import React, { Component } from "react";
import "../../css/feature.css";
import iPadSS11 from "../../images/iPadSS11.png";
import ArrowRightAltRoundedIcon from "@material-ui/icons/ArrowRightAltRounded";
import iPadSS11overlay from "../../images/iPadSS11overlay.png";

class Walkin extends Component {
  state = { page: 1, nextPage: null };

  render() {
    const explain = (
      <React.Fragment>
        <div className="title">Walk ins</div>
        <p>
          <span className="strong">Walk in: 2 @ 12:45</span>
        </p>
        <p>
          Tap the Walk In button in the top right, add 2 covers. Host instantly
          knows the options, and presents them in 3 buttons.
        </p>
        <p>
          <span className="strong">1. Seat</span>: You cannot simply seat this
          walk-in on a 2, so this button is greyed out.
          <br />
          <span className="strong">2. Quick</span>: If they just want a quick
          bite, they can have a table of 2 now if they're in-and-out in 60mins.
          <br />
          <span className="strong">3. Wait</span>: There's a 45min wait for the
          next 2.
        </p>
        <p>
          Then there's these same options for the other table sizes. You have
          several tables of 4 available, so you can{" "}
          <span className="strong">Seat</span> them on one of these.
        </p>
        <p>
          Host doesn't make decisions for you, it allows you to instantly
          present the best options to every diner.
        </p>
        <button onClick={() => this.setState({ page: 1, nextPage: explain })}>
          Back
        </button>
      </React.Fragment>
    );

    const fullWidth = this.props.maxWidth;
    return (
      <div
        className="feature-wrapper m-vert walkin"
        style={{ maxWidth: fullWidth }}
      >
        <div
          className={this.state.page === 1 ? "graphic grayscale" : "graphic"}
          style={{ position: "relative" }}
        >
          <div
            className={this.state.slideImg ? "show" : ""}
            style={{
              position: "absolute",
              width: this.state.imgEnlarge ? "429px" : "95%",
              top: "0",
              left: "0",
              transition: "0.5s ease",
            }}
          >
            <img
              src={iPadSS11}
              alt="booking widget"
              style={{ width: "100%" }}
            />
            <img
              onMouseOver={() =>
                this.setState({
                  imgEnlarge: this.state.imgEnlarge ? false : true,
                })
              }
              onMouseOut={() =>
                this.setState({
                  imgEnlarge: this.state.imgEnlarge ? false : true,
                })
              }
              src={iPadSS11overlay}
              alt="booking widget"
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                opacity: this.state.page === 2 ? "1" : "0",
                width: "100%",
                transition: ".5s ease",
                pointerEvents: this.state.page === 2 ? "auto" : "none",
              }}
            />
            <div
              className="show-hide"
              style={{ pointerEvents: "all" }}
              onClick={() =>
                this.setState({
                  slideImg: this.state.slideImg ? false : true,
                })
              }
            >
              <ArrowRightAltRoundedIcon
                style={{
                  transform: this.state.slideImg
                    ? "rotate(-180deg)"
                    : "rotate(0deg)",
                }}
              />
            </div>
          </div>
        </div>
        <div className="content" style={{ paddingTop: "50px" }}>
          <div
            className="section"
            style={{
              marginLeft: this.state.page === 1 ? "10px" : "-100%",
              width: "calc(100% - 10px)",
              padding: "0 10px 0 0",
            }}
          >
            <div className="title">Walk ins</div>
            <p>
              <span className="strong">Can I sit this walk-in of 2?</span> is
              often a really complex question.
            </p>
            <p>
              It's 1245, you have a walk in of 2. There's one table of 2 un-sat,
              but you have bookings coming in. It looks like you need it for
              another booking.
            </p>
            <p className="complex">
              Will a sat customer leave in time for that booking?
              <br />
              Or is their table rebooked anyway?
              <br />
              There's 4s available. Can I / should I put the 2 on a 4?
              <br />
              What about the table of 6? Better to sit a 2 than waste it.
              <br />
              If I can't sit them, what wait time should I offer?
              <br />
            </p>
            <p>
              There's more customers coming in, drinks on the bar and food ready
              to go. Speed matters.
            </p>
            <p>
              Host instantly answers all of these questions, and gives your
              staff the options they have to work with.
            </p>
            <button
              onClick={() => this.setState({ page: 2, nextPage: explain })}
            >
              Let's see how
            </button>
            {/* <p>
              <span className="grey">select an option</span>
            </p>
            <div className="options">
              <button
                onClick={() => this.setState({ page: 2, nextPage: phone })}
              >
                Phone bookings only
              </button>
              <button
                onClick={() => this.setState({ page: 2, nextPage: email })}
              >
                Email enquiry form
              </button>
              <button
                onClick={() => this.setState({ page: 2, nextPage: system })}
              >
                I have a booking system
              </button>
            </div> */}
          </div>
          <div
            className="section"
            style={{ left: this.state.page === 1 ? "100%" : "0" }}
          >
            {this.state.nextPage}
          </div>
        </div>
      </div>
    );
  }
}

export default Walkin;

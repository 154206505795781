import React, { Component } from "react";
import "../css/host.css";
import Quote from "../components/quote";
import SimplyServeSmall from "../images/SimplyServeSmall.png";
import Features from "../components/features";
import ReactGA from "react-ga";

class FeaturesPage extends Component {
  state = {
    page: "", //select, values, features
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  render() {
    const features = (
      <React.Fragment>
        <Quote text="I was concerned that a booking system would be slow and rigid, but I'm very impressed with Host's speed and flexibility." />
        <Features />
      </React.Fragment>
    );

    return (
      <div className="page host">
        <div className="page-title">
          SimplyServe
          <img src={SimplyServeSmall} alt="logo" />
          <div className="page-sub-title">let's take a look</div>
        </div>
        <div className="message-container" style={{}}>
          <div className="message" style={{ maxWidth: this.props.maxWidth }}>
            {/* <div className="title">
              <h2>Hi, I'm Chris</h2>
            </div>
            <div className="picture">
              <img src={Chris} alt="Chris" />
            </div>
            <div className="subtitle">
              <h4>founder of SimplyServe</h4>
            </div> */}

            <div className="content-wrapper">
              <div className="content">{features}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FeaturesPage;

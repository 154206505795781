import React, { Component } from "react";
import SimplyServeSmall from "../images/SimplyServeSmall.png";
import notFound from "../images/404NotFound.png";
import "../css/landing.css";
import ReactGA from "react-ga";

class PageNotFound extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  render() {
    return (
      <div className="page landing">
        <div className="page-title">
          SimplyServe
          <img src={SimplyServeSmall} alt="logo" />
          <div className="page-sub-title">get in touch</div>
        </div>
        <div className="message-container">
          <div className="message">
            <div className="content">
              <img
                src={notFound}
                alt="404 not found"
                style={{
                  maxWidth: "800px",
                  width: "90vw",
                  marginLeft: "0 5vw",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PageNotFound;

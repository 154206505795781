import React, { Component } from "react";
import SimplyServeSmall from "../images/SimplyServeSmall.png";
import welcome from "../images/welcome.png";
import about from "../images/about.png";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import "../css/landing.css";

class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  render() {
    return (
      <div className="page landing">
        <div className="page-title">
          SimplyServe
          <img src={SimplyServeSmall} alt="logo" />
          <div className="page-sub-title">get in touch</div>
        </div>
        <div className="message-container">
          <div className="message">
            <div className="content">
              <div className="landing-box">
                <h2>Host</h2>
                <div className="img">
                  <img src={welcome} alt="welcome" />
                  <div className="a-wrapper">
                    <Link to="/value">Value</Link>
                    <Link to="/features">Features</Link>
                  </div>
                </div>
                <div className="text">
                  <p>
                    Host is the online booking and hosting platform that the 21
                    <span className="super">st</span> century deserves.
                  </p>
                  <p>Hugely flexible, incredibly fast, intriguingly clever.</p>
                  {/* <p>
                  It's designed to do one thing: help you say{" "}
                  <span className="strong">yes</span> to more diners. Give your
                  staff the confidence to seat additional diners when it's busy
                  and accommodating more customer requests. Both whilst spending{" "}
                  <span className="strong">less time</span> figuring out your
                  constantly evolving availability.
                </p> */}
                  <p>
                    <span className="strong">Want to know more?</span>
                  </p>
                  <p>
                    The <span className="strong">value</span> page shows how
                    Host can help you seat more online bookings, phone bookings
                    &#038; walk-ins.
                  </p>
                  <p>
                    {" "}
                    The <span className="strong">features</span> section
                    explores Host in more detail.
                  </p>
                </div>
              </div>

              <div className="landing-box even">
                <h2>SimplyServe</h2>
                <div className="img">
                  <img src={about} alt="welcome" />
                  <div className="a-wrapper">
                    <Link to="/ourstory">Our Story</Link>
                    <Link to="/contact">Contact</Link>
                  </div>
                </div>
                <div className="text">
                  <p>
                    <span className="strong">
                      Smart software, simple business.
                    </span>
                  </p>
                  <p>
                    {" "}
                    No bundles, no restrictions. No hidden extras. No cunning
                    plan to take over the universe. We listen to and quickly act
                    on our customer's feedback, because all we really want is
                    for them to renew next year. That's it.
                  </p>
                  <p>
                    Add in a painless price point and you get leading software,
                    incredibly well priced, with fantastic service.
                  </p>
                  <p>
                    If you'd like to know more about us check out{" "}
                    <span className="strong">our story</span> or{" "}
                    <span className="strong">contact</span> us.
                  </p>
                  <p>We'd love to hear from you.</p>
                </div>
              </div>
              <p className="cta" style={{ marginBottom: "150px" }}>
                <h2>Let's Chat</h2>Host by SimplyServe gets a lot done. It's
                best to experience live and try it out. Once you’ve got stuck in
                for a week you’ll be dazzled by Host's simplicity.
                <br />
                <center>
                  <Link to="/demo">Book a demo</Link>
                </center>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;

import React, { Component } from "react";
import "../../css/feature.css";
import onlineres from "../../images/onlineres.PNG";

class OnlineBookings extends Component {
  state = { page: 1, nextPage: null };

  render() {
    const phone = (
      <React.Fragment>
        <div className="title">Online vs Phone</div>
        <p>
          Having a nice <span className="strong">Book Now</span> button on your
          website is the single easiest way to gain new diners.
        </p>
        <p>
          Phone bookings are essential, there's no doubt here. The thought{" "}
          <i>
            <b>if people want to come they will phone</b>
          </i>{" "}
          is often correct, but not always, and you will be losing tables each
          week.
        </p>
        <p>
          When anyone is browsing Google for a place to book and they land on
          your website, you <b>need</b> it to be easier for them to book with
          you than to leave your site and keep browsing.
        </p>{" "}
        <p>
          What is "easier"? That's subjective, but 40-50% of people prefer to
          book online.
        </p>
        <button onClick={() => this.setState({ page: 1 })}>back</button>
      </React.Fragment>
    );

    const email = (
      <React.Fragment>
        <div className="title">Live Booking vs Enquiry</div>
        <p>
          Every email you get is proof that diners want to book without phoning.
        </p>
        <p>
          It's 2021 and people expect instant answers. Many will see an enquiry
          form and not want to wait and see if you have space. They'll head back
          to Google and look at the next restaurant.
        </p>
        <p>
          Those who do fill out these forms have a huge intent to dine at your
          restaurant. But, on average, by the time you get back to them{" "}
          <span className="strong">30% are lost</span>. They've gone and made a
          confirmed reservation elsewhere.
        </p>
        <p>
          Even losing a couple of tables of diners per week to this{" "}
          <span className="strong">adds up to £1000s</span> really fast.
        </p>
        <button onClick={() => this.setState({ page: 1 })}>back</button>
      </React.Fragment>
    );

    const system = (
      <React.Fragment>
        <div className="title">Host, by SimplyServe</div>
        <p>Our booking software, Host, is smart.</p>
        <p>
          The amount of times I've tried to book a restaurant online, been told{" "}
          <span className="code">no availability</span>, just to phone up and
          find out they have the exact time I was refused online is shocking.
        </p>
        <p>
          It's scary to think how many diners, refused by poor booking systems,
          don't follow up with a phone call and just go elsewhere.
        </p>
        <p>
          This happens because poorly designed systems need constant manual
          fiddling to keep their availability accurate. Host can do all this
          manual work itself.{" "}
          <p>
            <span className="strong">
              Check out the core ways we keep your availability open below.
            </span>
          </p>
        </p>
        <button onClick={() => this.setState({ page: 1 })}>back</button>
      </React.Fragment>
    );

    const fullWidth = this.props.maxWidth;
    return (
      <div className="feature-wrapper m-vert" style={{ maxWidth: fullWidth }}>
        <div className="graphic grayscale">
          <img src={onlineres} alt="booking widget" />
        </div>
        <div className="content" style={{ paddingTop: "50px" }}>
          <div
            className="section"
            style={{
              marginLeft: this.state.page === 1 ? "0" : "-100%",
              width: "calc(100% - 10px)",
              padding: "0 10px 0 0",
            }}
          >
            <div className="title">Online Bookings</div>
            <p>40-50% of diners prefer to book online.</p>
            <p className="statement">
              Having a nice <span className="strong">Book Now</span> button on
              your website is the single easiest way to gain new diners.
            </p>
            <p>
              Some of these diners will not phone, and don't want to send an
              enquiry email. It's {new Date().getFullYear()}, people need
              instant booking confirmation, or they'll just go to the next
              restaurant on Google and make a booking on their website.
            </p>

            <p>
              Host's booking button is super easy to add to your website, and
              we'll even brand it to match.
            </p>
            <p>
              It's also the only booking button capable of making smart table
              allocations to not waste capacity in your restaurant.
            </p>
            {/* <p>
              <span className="grey">select an option</span>
            </p>
            <div className="options">
              <button
                onClick={() => this.setState({ page: 2, nextPage: phone })}
              >
                Phone bookings only
              </button>
              <button
                onClick={() => this.setState({ page: 2, nextPage: email })}
              >
                Email enquiry form
              </button>
              <button
                onClick={() => this.setState({ page: 2, nextPage: system })}
              >
                I have a booking system
              </button>
            </div> */}
          </div>
          <div
            className="section"
            style={{ left: this.state.page === 1 ? "100%" : "0" }}
          >
            {this.state.nextPage}
          </div>
          <div className="section">{system}</div>
          <div className="section">{email}</div>
          <div className="section">{phone}</div>
        </div>
      </div>
    );
  }
}

export default OnlineBookings;

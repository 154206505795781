import React, { Component } from "react";
import "../../css/feature.css";
import ipad from "../../images/ipad.png";
import ArrowRightAltRoundedIcon from "@material-ui/icons/ArrowRightAltRounded";

class Phone extends Component {
  state = {
    page: 1,
    nextPage: null,
    tables: [1, 2, 3, 4, 5],
    bookings: [
      {
        time: 1200,
        table: 1,
        manual: false,
        id: 1,
        opacity: 0,
        name: "Mrs Baker",
      },
      {
        time: 1200,
        table: 2,
        manual: false,
        id: 2,
        opacity: 0,
        name: "Mr Smith",
      },
      { time: 1230, table: 3, manual: false, id: 3, opacity: 0, name: "James" },
      {
        time: 1245,
        table: 4,
        manual: false,
        id: 4,
        opacity: 0,
        name: "Penelope",
      },
      {
        time: 1330,
        table: 1,
        manual: false,
        id: 5,
        opacity: 0,
        name: "Edward",
      },
      {
        time: 1330,
        table: 2,
        manual: false,
        id: 6,
        opacity: 0,
        name: "Ms Jones",
      },
      {
        time: 1400,
        table: 3,
        manual: false,
        id: 7,
        opacity: 0,
        name: "Katherine",
      },
      {
        time: 1500,
        table: 1,
        manual: false,
        id: 8,
        opacity: 0,
        name: "Louise",
      },
      {
        time: 1530,
        table: 3,
        manual: false,
        id: 9,
        opacity: 0,
        name: "Mr Mason",
      },
      { time: 1545, table: 4, manual: false, id: 10, opacity: 0, name: "Jack" },
      {
        time: 1415,
        table: 4,
        manual: "green",
        id: 11,
        opacity: 0,
        name: false,
      },
      { time: 1430, table: 5, manual: "blue", id: 12, opacity: 0, name: false },
    ],
    scenario: 0,
    explanation: false,
    solve: true,
    expand: false,
  };

  solve = () => {
    let bookings = this.state.bookings;
    const remainder = bookings.filter((booking) => booking.opacity === 0);

    if (remainder.length > 0) {
      remainder[0].opacity = 1;
      let timeout = 100;
      remainder.length === 3 && (timeout = 1500);
      remainder.length < 3 && (timeout = 500);
      this.setState(bookings, () => setTimeout(this.solve, timeout));
    } else {
      setTimeout(() => this.setState({ explanation: true, page: 2 }), 1000);
      //setTimeout(() => this.setState({ showSolution: true }), 3000);
    }
  };

  renderBookings = () => {
    let bookings = this.state.bookings;

    bookings.forEach((booking) => {
      let prevBookings = bookings.filter(
        (b) => b.table === booking.table && b.time <= booking.time
      );

      prevBookings = prevBookings.length - 1;
      booking.prev = prevBookings;
    });

    const result = (
      <React.Fragment>
        {bookings.map((booking) => (
          <div
            className={
              booking.manual === "green"
                ? "booking manual green"
                : booking.manual === "blue"
                ? "booking manual blue"
                : "booking"
            }
            style={{
              left: 17 * booking.table + 7 + "%",
              top: 27 + booking.prev * 24 + "%",
              fontSize: this.state.page === 1 ? "13px" : "13px",
              lineHeight: this.state.page === 1 ? "19px" : "19px",
              opacity: booking.opacity,
            }}
            key={booking.id}
          >
            {booking.time}
          </div>
        ))}
      </React.Fragment>
    );

    return result;
  };

  render() {
    const solution = (
      <React.Fragment>
        <div className="title">Explanation</div>
        <p>
          What's their best option? Well, that's up to you and the customer.
        </p>
        <p>
          It's a very human decision. Each option could be ideal, depending on
          the customer.
        </p>
        <p>
          Host simply provides your busy staff with these three options in less
          than a 10<span className="super">th</span> of a second.
        </p>
        <p>
          This helps your staff maximise capacity, whilst delivering excellent
          service.
        </p>
        <button
          className="close"
          onClick={() => {
            const bookings = this.state.bookings;
            bookings.forEach((booking) => (booking.opacity = 0));
            this.setState({
              expand: false,
              page: 1,
              explanation: false,
              solve: true,
              bookings,
            });
          }}
        >
          back
        </button>
      </React.Fragment>
    );
    const explanation = (
      <React.Fragment>
        <p>Booking at 1430?</p>
        <p>There are 3 options:</p>
        <ul
          style={{
            listStyleType: "none",
            padding: "0",
          }}
        >
          <li>
            <span className="strong" style={{ color: "green" }}>
              T4 Perfect:
            </span>{" "}
            If they can do 1415?
          </li>
          <li>
            or{" "}
            <span className="strong" style={{ color: "green" }}>
              T4 Perfect:
            </span>{" "}
            If 1h15m enough?
          </li>
          <li>
            or <span className="strong">T5 OK:</span> Yes, 1430 is available.
          </li>
        </ul>
        <div className="reset-next" style={{ marginTop: "10px" }}>
          <button
            style={{ margin: "0", marginBottom: "10px" }}
            onClick={() => {
              const bookings = this.state.bookings;
              bookings.forEach((booking) => (booking.opacity = 0));
              this.setState({ explanation: false, solve: true });
            }}
          >
            Replay
          </button>
          {/* <button
            className="close"
            onClick={() => {
              const bookings = this.state.bookings;
              bookings.forEach((booking) => (booking.opacity = 0));
              this.setState({
                expand: false,
                page: 1,
                explanation: false,
                solve: true,
                bookings,
              });
            }}
          >
            Close
          </button> */}
          <button
            className="explain"
            onClick={() => {
              const bookings = this.state.bookings;
              bookings.forEach((booking) => (booking.opacity = 0));
              this.setState({
                expand: false,
              });
            }}
          >
            Explain
          </button>
        </div>
      </React.Fragment>
    );
    let tableFontSize = "16px";
    this.state.page !== 1 && (tableFontSize = "16px");

    let bookings = <React.Fragment>{this.renderBookings()}</React.Fragment>;

    const fullWidth = this.props.maxWidth;

    return (
      <div className="phone feature-wrapper" style={{ maxWidth: fullWidth }}>
        <div
          className="content"
          style={{ flex: this.state.page !== 1 ? "50 1" : "50 1" }}
        >
          <div
            className={this.state.page === 1 ? "section" : "section expand"}
            style={{
              marginLeft: !this.state.explanation
                ? "0"
                : "-" + fullWidth / 2 + "px",
              width: fullWidth / 2 - 20 + "px",
            }}
          >
            <div className="title">Phone Bookings</div>
            <p>Host makes telephone bookings quicker and easier. Here's how.</p>
            <p>
              <span className="strong">Phone reservation, Paper vs Host</span>
            </p>
            <p>
              A small restaurant has 5 tables and the bookings on the iPad. The
              phone rings, a potential customer would like a table at 1430.{" "}
            </p>
            <p>What's their best option?</p>

            <button
              className="lets-see"
              onClick={() =>
                this.setState({
                  nextPage: solution,
                  expand: true,
                })
              }
            >
              Let's see
            </button>
          </div>
          <div
            className="section"
            style={{
              width: fullWidth / 2 - 20 + "px",
              opacity: 1,
            }}
          >
            {solution}
          </div>
        </div>
        <div
          className="graphic"
          style={{ flex: this.state.page !== 1 ? "50 1" : "50 1" }}
        >
          <div
            className={this.state.expand ? "section show" : "section"}
            style={{
              position: "relative",
            }}
          >
            <div
              className="show-hide"
              onClick={() => {
                const bookings = this.state.bookings;
                bookings.forEach((booking) => (booking.opacity = 0));
                this.setState({
                  expand: this.state.expand ? false : true,
                });
              }}
            >
              <ArrowRightAltRoundedIcon />
            </div>
            <img
              src={ipad}
              alt="booking widget"
              style={{
                width: "100%",
                zIndex: 200,
                pointerEvents: "none",
              }}
            />

            <div className="widget phone">
              <div
                className="controls"
                style={{
                  fontSize: this.state.expand ? "16px" : "16px",
                  justifyContent: "flex-start",
                  background: "transparent",
                  top: "0",
                }}
              >
                <ul
                  style={{
                    margin: "0",
                    paddingLeft: "10px",
                    padding: "0",
                    fontSize: this.state.expand ? "16px" : "16px",
                    lineHeight: this.state.expand ? "31px" : "31px",
                    opacity: this.state.explanation ? "0" : "1",
                    transition: "0.5s ease",
                    listStyleType: "none",
                  }}
                >
                  {this.state.bookings
                    .filter((booking) => booking.name !== false)
                    .map((booking) => (
                      <li
                        key={booking.id}
                        style={{
                          opacity: booking.opacity === 0 ? "1" : "0.6",
                          marginTop: "0",
                        }}
                      >
                        {booking.time} {booking.name}
                      </li>
                    ))}
                </ul>
                <button
                  className="flash"
                  style={{
                    position: "absolute",
                    right: "0",
                    top: "50%",
                    opacity: this.state.solve ? "1" : "0",
                    zIndex: 150,
                    transform: this.state.expand
                      ? "translateY(-50%) scale(1)"
                      : "translateY(-50%) scale(1)",
                  }}
                  onClick={() => {
                    this.setState({ solve: false }, () =>
                      setTimeout(this.solve, 1000)
                    );
                  }}
                >
                  Solve
                </button>
              </div>
              <div
                className="explanation"
                style={{
                  fontSize: this.state.expand ? "16px" : "16px",
                  opacity: this.state.explanation ? "1" : "0",
                  pointerEvents: this.state.explanation ? "unset" : "none",
                  paddingLeft: "0",
                  paddingRight: "0",
                }}
              >
                {explanation}
              </div>
              <div className="tables-holder">
                {bookings}
                <div className="header" style={{ fontSize: tableFontSize }}>
                  <p>Tables</p>
                </div>
                {this.state.tables.map((table) => (
                  <div
                    key={table}
                    className="table"
                    style={{ fontSize: tableFontSize }}
                  >
                    <p>{"T" + table}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Phone;

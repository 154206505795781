import React, { Component } from "react";
import SimplyServeSmall from "../images/SimplyServeSmall.png";
import ReactGA from "react-ga";

class Terms extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  };
  render() {
    return (
      <div className="page terms">
        {/* <div className="page-title">
      Pricing
      <div className="page-sub-title">keep it simple</div>
    </div> */}
        <div className="page-title">
          SimplyServe
          <img src={SimplyServeSmall} alt="logo" />
          <div className="page-sub-title">Terms of Service</div>
        </div>
        <div className="message-container">
          <div className="message">
            <div
              className="content"
              style={{
                width: this.props.maxWidth - 20 + "px",
                padding: "10px",
              }}
            >
              <h2>Terms of Service</h2>
              <h4>Information about us</h4>

              <p>
                These are the terms of service (“Terms”) for Simply Serve
                Somerset Ltd (“us/we/our”) which apply to all use of our
                SimplyServe subscription service (“Service”).
              </p>
              <p>
                We reserve the right to amend these Terms from time to time and
                will post a message on our homepage along with the new version
                of the Terms if that happens. Your continued use of the Service
                will be deemed to be acceptance of any new Terms.
              </p>
              <h4>Who we are</h4>

              <p>
                Simply Serve Somerset Ltd is a limited liability company
                incorporated in England and Wales. Our company number is
                13618439, and our registered office is at Unit 5 Ducketts Wharf,
                South Street, Bishop's Stortford, England, CM23 3AR.
              </p>

              <h4>Our contract with you</h4>

              <p>
                If you get in touch with us by email to request our Service, we
                will send you an email to confirm whether your request is
                accepted and will provide you with a copy of these Terms. Our
                Contract with you is formed when you accept these Terms and is
                comprised of:
              </p>
              <ul>
                <li>
                  our email to you confirming details of your subscription
                  package (“Registration Confirmation”);
                </li>
                <li>
                  your email to us confirming your acceptance of these Terms;
                  and
                </li>
                <li>these Terms</li>
              </ul>
              <p>
                Please note we reserve the right at our discretion to reject
                applications to use our Service.
              </p>
              <p>The Contract will run on an annual subscription basis.</p>
              <h4>Renewal of Accounts</h4>

              <p>
                For annual Contracts, the initial subscription term is one year.
                Your Contract will automatically renew for successive periods of
                one year, unless you cancel the Contract before the end of the
                initial subscription term (or before the end of the current
                renewal period).
              </p>
              <h4>Cancellation</h4>

              <p>
                You can choose to stop the Service at any time by cancelling
                your Contract via email, in which case your Contract will expire
                at the end of the initial subscription term or current renewal
                period (as applicable). No further subscription fees will be
                charged but we shall not be obliged to refund any fees paid.
              </p>
              <h4>Termination</h4>

              <p>
                We shall be entitled to terminate the Contract at any time if
                you are in material breach of the Contract (e.g. fail to pay
                subscription fees) and either you fail to remedy the breach
                within the timescale we request or the breach is not capable of
                being remedied. We shall also be entitled to terminate the
                Contract if you become insolvent. Where we terminate the
                Contract due to your breach or insolvency we shall not be
                obliged to refund any subscription fees you may have paid us.
              </p>
              <p>
                These rights of termination will also apply to you against us.
                Where you terminate the Contract due to our insolvency or our
                material breach of the Contract (where we have not remedied the
                breach within 30 days of your written notification to do so, or
                the breach is not capable of being remedied), then you shall be
                entitled to a refund of any fees paid for services which have
                not been performed.
              </p>
              <h4>Use of your Account</h4>

              <p>
                Once the Contract is formed, and subject to you and all users
                you have authorised to access your Account (“Authorised Users”)
                complying with these Terms, you will be entitled to use, and
                permit Authorised Users to use, the Services via your Account
                solely for collecting, organising, and managing customer
                reservations and walk-ins. You are responsible for your
                Authorised Users use of the Service.
              </p>
              <p>
                Authorised Users use of the Services will be subject to these
                Terms. If you discover any unauthorised use of the Service you
                must promptly let us know.
              </p>
              <p>
                You must keep your password and account details secure and
                ensure that only you and Authorised Users access your Account.
              </p>
              <p>
                You and your Authorised Users shall not store or transmit any
                computer virus, or any material during the use of the Services
                that:
              </p>
              <ul>
                <li>
                  is unlawful, threatening, defamatory, obscene, infringing,
                  discriminatory or is racially offensive;{" "}
                </li>

                <li>facilitates or encourages illegal activity;</li>

                <li>depicts sexually explicit images; or</li>

                <li>
                  promotes unlawful violence or causes damage or injury to any
                  person or property,
                </li>
              </ul>
              <p>
                and we reserve the right, without liability to you, to disable
                your access to any material that breaches this provision.
              </p>
              <p>
                You are responsible for complying with the laws of the country
                from where you use the Service and to ensure you have all
                necessary permission and consents in place in relation to your
                use of the Service.
              </p>
              <h4>Payment</h4>

              <p>
                A subscription fee is due in advance for use of the Service
                (i.e. annually in advance for annual Contracts) and is
                non-refundable. The subscription fee will be detailed in your
                Registration Confirmatio. It will be clear before you purchase
                any Service whether VAT or other applicable taxes are included
                in the price or still to be added.
              </p>
              <p>
                If you have a Standard Account, you must ensure that we have
                valid, up-to-date and complete contact and billing details for
                the duration of your Contract. We will invoice you on or after
                the first day of the initial subscription term, and thereafter
                on or after the first day of each subsequent renewal period, and
                you shall pay each invoice issued by us within 14 days of the
                date of such invoice.
              </p>
              <p>
                here you have failed to make payment of our invoice on the due
                date for payment we will contact you and suspend access to your
                Account until payment has been made.
              </p>
              <p>
                We shall be entitled to increase our subscription fees upon 14
                days’ prior notice to you, but not more than once during any 12
                month period during the Contract.
              </p>
              <p>
                Please note that you are not entitled to a refund of
                subscription fees paid to us unless you terminate the Contract
                because of our material breach or insolvency in accordance with
                the ‘Our contract with you’ section above.
              </p>
              <h4>Ownership of Data</h4>

              <p>
                We do not monitor Your Data posted or provided as part of the
                Service. You are solely responsible for all the data you enter
                or upload using the Service (“Your Data”), and any intellectual
                property rights in Your Data will be owned by you. We will
                comply with our{" "}
                <a href="https://simplyserve.io/privacy-policy">
                  Privacy Policy
                </a>{" "}
                in relation to use of Your Data.
              </p>
              <p>
                In the event of any loss or damage to Your Data, your sole and
                exclusive remedy shall be for us to use reasonable commercial
                endeavours to restore the lost or damaged data from the latest
                back-up under our archiving procedure. We shall not be
                responsible for any loss, damage or disclosure of Your Data
                caused by any third party.
              </p>
              <p>
                Where the Contract has expired or the Contract has been
                terminated by either of us, you may request a copy of Your Data
                from us within 12 months of the expiry or termination date (as
                applicable). After that period we will delete your Account,
                including invoice details, and all Your Data. It is your
                responsibility to either export and/or copy Your Data prior to
                the expiry or termination date, or request a copy from us within
                12 months days of such date.
              </p>
              <h4>Data Protection</h4>

              <p>
                If Your Data includes any personal data (as that term is defined
                in the Data Protection Legislation), then you acknowledge that
                we shall be deemed the data processor and you are the data
                controller in respect of any such personal data (where
                “controller”, “processor” and “personal data” have the meanings
                as defined in Data Protection Legislation).
              </p>
              <p>
                We shall comply with our, and you shall comply with your,
                respective obligations under the Data Protection Legislation
                (where “Data Protection Legislation” means (i) unless and until
                it is no longer directly applicable in the UK, the General Data
                Protection Regulation ((EU) 2016/679) and any national
                implementing laws, regulations and secondary legislation, as
                amended or updated from time to time, in the UK; and (ii) any
                successor legislation to the GDPR or the Data Protection Act
                1998 from time to time in force in the UK. This section is in
                addition to, and does not relieve, remove or replace, your or
                our obligations under Data Protection Legislation.
              </p>
              <p>
                The scope, nature and purpose of processing by us is the
                provision of our Service. The type of personal data will be set
                out in any data you upload using the Service and the categories
                of data subjects are any individuals that may be referred to
                within such data. The duration of the processing is that of our
                retention period, which is a maximum of 12 months from expiry or
                termination of your Account.
              </p>
              <p>
                You must ensure that you have all necessary consents and notices
                in place to enable lawful transfer of any personal data to us
                for the duration and purposes of this Contract.
              </p>
              <p>
                We shall, in relation to any personal data processed in
                connection with the performance by us of our obligations under
                this Contract:
              </p>
              <ul>
                <li>
                  process the personal data only on your written instructions
                  (unless otherwise required by applicable law);
                </li>

                <li>
                  maintain records of any processing of personal data that we
                  carry out on your behalf;
                </li>

                <li>
                  ensure that we have in place appropriate technical and
                  organisational measures to protect against unauthorised or
                  unlawful processing of personal data and against accidental
                  loss or destruction of, or damage to, personal data;
                </li>

                <li>
                  ensure that all personnel who have access to and/or process
                  personal data are obliged to keep the personal data
                  confidential;
                </li>

                <li>
                  assist you, at your cost, in responding to any request from a
                  data subject and in ensuring compliance with your obligations
                  under the Data Protection Legislation with respect to
                  security, breach notifications, impact assessments and
                  consultations with supervisory authorities or regulators;
                </li>
                <li>
                  notify you without undue delay on becoming aware of a personal
                  data breach; and
                </li>

                <li>
                  at your written direction, delete or return personal data and
                  copies thereof to you on termination of this Contract (unless
                  required by applicable law(s) to store the personal data).
                </li>
              </ul>
              <p>
                <em>
                  You consent to us appointing third-party processors of
                  personal data who assist with web-hosting under this Contract
                  and, in particular, the appointment of Amazon Web Services
                  (AWS). You acknowledge that our appointment of AWS may involve
                  transfer of personal data outside the EEA and accordingly you
                  consent to such transfer.
                </em>
              </p>
              <p>
                We confirm that we have entered, or will enter (as the case may
                be), a written agreement with any such third-party processor
                incorporating terms which are substantially similar to those set
                out in this section.
              </p>
              <p>You warrant that:</p>
              <ul>
                <li>
                  you are not aware of any circumstances likely to give rise to
                  breach of the Data Protection Legislation (including any
                  personal data breach);
                </li>
                <li>
                  you have a lawful basis under the Data Protection Legislation
                  to transfer the personal data to us for processing under this
                  Contract and such use will comply with all Data Protection
                  Legislation;
                </li>
                <li>
                  you will promptly notify us of any action we must take to
                  assist you with ensuring compliance with your obligations
                  under Data Protection Legislation, including with request to
                  security, breach notifications, impact assessments and
                  consultations with supervisory authorities or regulators; and
                </li>
                <li>
                  the personal data is necessary, adequate, relevant, accurate
                  and up-to-date and you will notify us promptly of any changes
                  to the personal data.
                </li>
              </ul>
              <h4>Availability of Service</h4>

              <p>
                We shall use commercially reasonable endeavours to make the
                Services available 24 hours a day, seven days a week, except for
                planned or unplanned maintenance. We will try and ensure all
                maintenance is not carried out during key service hours.
              </p>
              <p>
                We will endeavour to respond to all customer support queries
                within 48 hours.
              </p>
              <p>
                You are solely responsible for procuring and maintaining your
                network connections and telecommunications links from your
                systems to our data centres. We are not liable for any delays,
                delivery failures and any other loss or damage arising from or
                relating to your network connections or telecommunications links
                or caused by the internet.
              </p>
              <h4>Our rights in the service</h4>

              <p>
                The SimplyServe Service is our proprietary service, and all
                intellectual property rights in it are owned by or validly
                licensed to us.
              </p>
              <p>
                Software and related documentation provided in relation to the
                Service is only provided in relation to your use of the Service
                and are not provided, or to be used, for any other purpose.
                Except where this is part of your legitimate use of the Service
                you are not permitted to copy, modify, republish, download,
                display or distribute all or any part of such software or
                documentation in any form or media or by any means. Nor are you
                permitted to reverse compile, disassemble, or reverse engineer
                such software or make use of such software or documentation to
                build a product or service which competes with our Service.
              </p>
              <h4>Liability</h4>

              <p>
                This section sets out our entire liability to you in respect of
                any breach of Contract or any use made by you of the Service.
              </p>
              <p>
                The Service is provided on an ‘as is’ basis and we do not
                guarantee that the Service will be suitable for your intended
                use. However, nothing in these Terms seeks to exclude our
                liability for death or personal injury caused by our negligence,
                or for fraud or fraudulent misrepresentation.
              </p>
              <p>
                Subject to the paragraph above, we exclude all other liability
                to the extent permitted by law and we shall not be liable for
                any loss of business, loss of profit, loss or corruption of data
                or for any indirect or consequential loss. Except for
                liabilities we have expressly not excluded, our total aggregate
                liability arising under the Contract or otherwise relating to
                the Service shall be limited to the total of subscription fees
                paid by you in the one month period preceding the date on which
                the claim arose, or where you have paid for a block period in
                advance, the average monthly charge for that period.
              </p>
              <h4>General legal provisions</h4>

              <p>
                We shall have no liability to you under the Contract if we are
                prevented from or delayed in performing our obligations under
                the Contract or from carrying on our business by acts or events
                beyond our reasonable control.
              </p>
              <p>
                If we choose to waive any particular right we have under the
                Contract on any particular occasion this does not prevent us
                from exercising that right on another occasion.
              </p>
              <p>
                If any part of the Contract is held by a court of law (or
                similar forum) to be invalid or unenforceable, this shall not
                affect the validity or enforceability of the rest of the
                Contract.
              </p>
              <p>
                You are not entitled to transfer or assign your rights and
                obligations under the Contract to anyone else without our prior
                written permission.
              </p>
              <p>
                If there are any disputes arising out of your use of the Service
                or relating to the Contract then these will be governed by the
                laws of England and Wales. If either party require to raise
                court proceedings in relation to any such dispute then these
                proceedings must be raised in England and Wales.
              </p>
              <h4>Your concerns</h4>

              <p>
                If you have any concerns about material which appears on our
                site, please contact{" "}
                <a href="mailto:terms@simplyserve.io">terms@simplyserve.io</a>.
              </p>
              <p>
                If you wish to contact us in writing or a provision of these
                Terms requires you to give us a notice in writing (for example,
                to cancel the Contract), please do so by email at{" "}
                <a href="mailto:support@simplyserve.io">
                  support@simplyserve.io
                </a>
                . If we have to contact you or give you notice in writing, we
                will do so by e-mail, by hand, or by pre-paid post to the
                address details provided by you when setting up, or updating,
                your Account.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Terms;

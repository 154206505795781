import React, { Component } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import SimplyServeSmall from "../images/SimplyServeSmall.png";
import ReactGA from "react-ga";
import "../css/contact.css";

class TrialSignUp extends Component {
  state = {
    formData: {
      yourName: "",
      email: "",
      phone: "",
      restaurantName: "",
      restaurantAddress: undefined,
      // time: "10am",
      // date: null,
      message: undefined,
    },
    submitting: false,
    message: "",
  };
  constructor(props) {
    super(props);

    this.recaptchaRef = React.createRef();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  setDate = (date) => {
    console.log(date);
    let formData = this.state.formData;
    formData.date = date;
    this.setState({ formData }, () => console.log(this.state.formData));
  };
  submitForm = async (event) => {
    event.preventDefault(0);
    this.setState({ submitting: true });
    await this.postSubmission();
    this.setState({ submitting: false });
  };

  postSubmission = async () => {
    const formId = "EK4E6s1h";
    const formSparkUrl = "https://submit-form.com/" + formId;
    const payload = {
      ...this.state.formData,
      "g-recaptcha-response": this.state.recaptchaToken,
    };

    try {
      const result = await axios.post(formSparkUrl, payload);
      window.scrollTo(0, 0);
      this.setState({
        message: {
          class: "green",
          message:
            "Message received! We'll be in touch to set your account up shortly.",
        },
        formData: {
          yourName: "",
          email: "",
          phone: "",
          restaurantName: "",
          restaurantAddress: undefined,
          // date: payload.date,
          // time: "10am",
          message: undefined,
        },
      });
      this.recaptchaRef.current.reset();
    } catch (err) {
      this.setState({
        message: {
          class: "red",
          message: "Sorry, there was a problem. Please try again.",
        },
      });
    }
  };

  updateFormControl = (event) => {
    const { id, value } = event.target;
    const formKey = id;
    const updateFormState = { ...this.state.formData };
    updateFormState[formKey] = value;
    this.setState({ formData: updateFormState });
  };

  updateRecaptchaToken = (token) => {
    this.setState({ recaptchaToken: token });
  };

  render() {
    const recaptchaKey = process.env.REACT_APP_ReCAPTCHA_KEY;

    return (
      <div className="page contact">
        <div className="page-title">
          SimplyServe
          <img src={SimplyServeSmall} alt="logo" />
          <div className="page-sub-title">100 day free trial</div>
        </div>
        <div className="message-container">
          <div className="message">
            <div className="content">
              <h2>100 Day Free Trial</h2>

              {this.state.message && (
                <div className={this.state.message.class}>
                  {this.state.message.message}
                </div>
              )}
              <form onSubmit={this.submitForm}>
                <label for="restaurantName">Restaurant Name</label>
                <input
                  type="text"
                  id="restaurantName"
                  name="restaurantName"
                  placeholder="Restaurant"
                  required=""
                  value={this.state.formData.restaurantName}
                  onChange={this.updateFormControl}
                />
                <label for="restaurantAddress">Restaurant Address</label>
                <textarea
                  id="restaurantAddress"
                  name="restaurantAddress"
                  placeholder="Address"
                  required=""
                  onChange={this.updateFormControl}
                  value={this.state.formData.restaurantAddress}
                />

                <label for="yourName">Your Name</label>
                <input
                  type="text"
                  id="yourName"
                  name="yourName"
                  placeholder="Name"
                  required=""
                  value={this.state.formData.yourName}
                  onChange={this.updateFormControl}
                />
                <label for="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  required=""
                  value={this.state.formData.email}
                  onChange={this.updateFormControl}
                />
                <label for="phone">Phone</label>
                <input
                  type="number"
                  id="phone"
                  name="phone"
                  placeholder="Phone"
                  required=""
                  value={this.state.formData.phone}
                  onChange={this.updateFormControl}
                />

                <label for="message">Additional</label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Anything you'd like us to know?"
                  required=""
                  onChange={this.updateFormControl}
                  value={this.state.formData.message}
                />

                <ReCAPTCHA
                  ref={this.recaptchaRef}
                  sitekey={recaptchaKey}
                  onChange={this.updateRecaptchaToken}
                />
                <button type="submit" disabled={this.state.submitting}>
                  {this.state.submitting ? "Signing up..." : "Sign Up Now"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TrialSignUp;

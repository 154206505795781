import React, { Component } from "react";
import "../css/autoallocate.css";
class AutoAllocate extends Component {
  animation = false;

  constructor() {
    super();
    const y = 500 * 1.5;
    this.state = {
      scaler_width: 1250,
      height: 900,
      id2_next: [
        { id: "id2_blackout", delay_after: 0 * y, style: "opacity:0" },
        { id: "id2_note_1", delay_after: 0 * y, style: "opacity:0" },
        { id: "id2_note_2", delay_after: 0 * y, style: "opacity:0" },
        { id: "id2_note_replay", delay_after: 3 * y, style: "opacity:0" },
        { id: "id2_call_1", delay_after: 3 * y, style: "right:10px;" },
        { id: "id2_time_1", delay_after: 2 * y, style: "opacity:1" },
        { id: "id2_1400", delay_after: 2 * y, style: "right:5px;bottom:10px" },
        { id: "id2_call_1", delay_after: 2 * y, style: "right:30px;opacity:0" },
        { id: "id2_call_2", delay_after: 3 * y, style: "right:30px;" },
        {
          id: "id2_time_1",
          delay_after: 2 * y,
          style: "opacity:1;right:70px;",
        },
        { id: "id2_1400", delay_after: 2 * y, style: "right:70px;bottom:10px" },
        { id: "id2_call_2", delay_after: 2 * y, style: "right:30px;opacity:0" },
        { id: "id2_call_3", delay_after: 3 * y, style: "right:30px;" },
        {
          id: "id2_time_1",
          delay_after: 2 * y,
          style: "opacity:0;right:70px;",
        },
        { id: "id2_time_2", delay_after: 2 * y, style: "opacity:1;" },
        { id: "id2_1400", delay_after: 2 * y, style: "" },
        { id: "id2_call_3", delay_after: 2 * y, style: "right:30px;opacity:0" },
        { id: "id2_call_4", delay_after: 3 * y, style: "right:30px;" },
        { id: "id2_time_2", delay_after: 2 * y, style: "opacity:0;" },
        { id: "id2_time_3", delay_after: 2 * y, style: "opacity:1;" },
        { id: "id2_1200", delay_after: 2 * y, style: "right:70px;" },
        { id: "id2_call_4", delay_after: 3 * y, style: "right:30px;opacity:0" },
        { id: "id2_note_replay", delay_after: 0 * y, style: "opacity:1" },
        { id: "id2_note_1", delay_after: 0 * y, style: "opacity:1" },
        { id: "id2_note_2", delay_after: 0 * y, style: "opacity:1" },
        { id: "id2_blackout", delay_after: 2 * y, style: "opacity:0" },
      ],
    };
  }

  //   scale2 = () => {
  //     const scaler_width = this.state.scaler_width;
  //     const scale = scaler_width / 1250;

  //     const height = scale * 900;
  //     const diffInHeight = 900 - height;

  //     document
  //       .getElementById("id2_anim2_scaler")
  //       .setAttribute(
  //         "style",
  //         "transform:scale(" +
  //           scale.toString() +
  //           ");top:" +
  //           (-diffInHeight / 2).toString() +
  //           "px"
  //       );
  //     document
  //       .getElementById("id2_container")
  //       .setAttribute("style", "height:" + height.toString() + "px");
  //     return true;
  //   };

  //   scale2();
  //   window.onresize = () => {scale2();  scale();}

  anim2ate = (i = 0) => {
    let id2_next = this.state.id2_next;
    let delay_before;
    i === 0 ? (delay_before = 0) : (delay_before = id2_next[i - 1].delay_after);
    this.animation = setTimeout(() => {
      let quit = false;
      document.getElementById(id2_next[i].id)
        ? document
            .getElementById(id2_next[i].id)
            .setAttribute("style", id2_next[i].style)
        : (quit = true);
      i++;
      i < id2_next.length && !quit && this.anim2ate(i);
    }, delay_before);
  };

  reset2 = () => {
    let id2_next = this.state.id2_next;
    let array = [];
    id2_next.forEach((elem) => {
      array.push(elem.id);
    });
    array = Array.from(new Set(array));
    array.forEach((elem) => {
      document.getElementById(elem).setAttribute("style", "");
    });
    this.anim2ate();
  };

  componentWillMount = () => {
    clearTimeout(this.animation);
  };

  render() {
    return (
      <div
        className="anim2_container"
        id="id2_container"
        // style="height:900px;width:1250px"
      >
        <div
          className="anim2_scaler"
          id="id2_anim2_scaler"
          //   style="transform:scale(1);top:0"
        >
          <div
            className="call"
            id="id2_call_1"
            //   style="right:30px;opacity:0"
          >
            Can I book <span className="strong">&nbsp;table 4&nbsp;</span> for
            1230?
          </div>
          <div
            className="call"
            id="id2_call_2"
            //   style="right:30px;opacity:0"
          >
            Actually, can I have <span className="strong">&nbsp;table 3</span>?
          </div>
          <div
            className="call"
            id="id2_call_3"
            //   style="right:30px;opacity:0"
          >
            I need to move to 1300.
          </div>
          <div
            className="call"
            id="id2_call_4"
            //   style="right:30px;opacity:0"
          >
            Actually, make that 1330.
          </div>
          <div className="tables">
            <div className="table-label">
              <span> tables </span>
            </div>
            <div className="table">
              <h1>T1</h1>
            </div>
            <div className="table">
              <h1>T2</h1>
            </div>
            <div className="table">
              <h1>T3</h1>
            </div>
            <div className="table">
              <h1>T4</h1>
            </div>
          </div>
          <div
            className="time"
            id="id2_time_1"
            //   style="opacity:0;right:320px;"
          >
            1230
          </div>
          <div
            className="time time_2"
            id="id2_time_2"
            //   style="opacity:0;"
          >
            1300
          </div>
          <div
            className="time time_3"
            id="id2_time_3"
            //   style="opacity:1;"
          >
            1330
          </div>
          <div
            className="time auto first"
            id="id2_1200"
            //   style="right:320px;"
          >
            1200
          </div>
          <div className="time auto second" id="id2_1400">
            1400
          </div>
          <div
            className="blackout"
            id="id2_blackout"
            //   style="opacity:0.6"
          ></div>
          <div className="final_notes" id="id2_final_notes">
            <div
              className="note call staff"
              id="id2_note_1"
              // style="opacity:1"
            >
              <div className="time">1230</div>Manually assigned bookings look
              like this.
            </div>
            <div
              className="note call"
              id="id2_note_2"
              // style="opacity:1"
            >
              <div className="time auto">1200</div>
              Auto assigned bookings look like this. <br />
              <br />
              Let's see how Host allocates around manual booking changes.
            </div>
            <div
              className="note replay"
              // style="opacity:1"
              id="id2_note_replay"
            >
              <div className="replay-button" onClick={() => this.reset2()}>
                <div className="replay-img"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AutoAllocate;

import React, { Component } from "react";
import SimplyServeSmall from "../images/SimplyServeSmall.png";
import "../css/pricing.css";
import ReactGA from "react-ga";
class Pricing extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  state = {};
  render() {
    return (
      <div className="page pricing">
        {/* <div className="page-title">
          Pricing
          <div className="page-sub-title">keep it simple</div>
        </div> */}
        <div className="page-title">
          SimplyServe
          <img src={SimplyServeSmall} alt="logo" />
          <div className="page-sub-title">honest pricing</div>
        </div>
        <div className="message-container">
          <div className="message" style={{ marginTop: "150px" }}>
            <div className="content" style={{ padding: "10px" }}>
              {/* <Quote text="I was amazed to learn how many diners we were losing every single week." /> */}

              <div className="price-wrapper">
                <div className="price">
                  <span>£59</span> per month
                </div>
                <div className="price-label">excl. vat, billed annually</div>
              </div>
              <div className="extra-info">
                <p>No table limits. No booking limits. No restrictions.</p>{" "}
                <p>Simple.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Pricing;

import React, { Component } from "react";
import Home from "./pages/home";
import About from "./pages/about";
import Value from "./pages/value";
import FeaturesPage from "./pages/features";
import Pricing from "./pages/pricing";
import FAQs from "./pages/faqs";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import Contact from "./pages/contact";
import Demo from "./pages/demo";
import Trial from "./pages/trial";
import PageNotFound from "./pages/pagenotfound";
import TrialSignUp from "./pages/trialSignUp";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import SimplyServeSmall from "./images/SimplyServeSmall.png";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import ReactGA from "react-ga";
import "./App.css";
import "./css/navbar.css";
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

class App extends Component {
  state = {
    mobileNav: false,
    links: [
      { display: "Home", path: "/", route: <Home /> },
      { display: "Our Story", path: "/ourstory", route: <About /> },
      { display: "Value", path: "/value", route: <Value /> },
      { display: "Features", path: "/features", route: <FeaturesPage /> },
      { display: "Pricing", path: "/pricing", route: <Pricing /> },
      { display: "FAQ", path: "/faq", route: <FAQs /> },
      { display: "Contact", path: "/contact", route: <Contact /> },
    ],
  };
  constructor() {
    super();
    this.state.width = window.innerWidth;
  }
  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
    console.log("ww", window.innerWidth);
  };
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  addLinks = () => {
    return this.state.links.map((link) => (
      <li key={link.display}>
        <Link
          to={link.path}
          onClick={() => {
            window.scrollTo(0, 0);
            this.setState({ mobileNav: false });
          }}
        >
          {link.display}
        </Link>
      </li>
    ));
  };

  addRoutes = () => {
    return this.state.links.map((link) => (
      <Route key={link.display} path={link.path} exact>
        {link.route}
      </Route>
    ));
  };

  render() {
    let maxWidth = 800;
    this.state.width < 820 && (maxWidth = this.state.width - 20);
    return (
      <Router>
        <div>
          <div
            className="mobile-nav"
            style={{
              transform: this.state.mobileNav
                ? "translateY(0)"
                : "translateY(-100%)",
            }}
          >
            <ul>
              <li>
                <div className="right">
                  <a className="log-in" href="https://app.simplyserve.io">
                    Log in
                  </a>
                  <Link
                    to="/demo"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      this.setState({ mobileNav: false });
                    }}
                  >
                    Book Demo
                  </Link>
                </div>
              </li>
              {this.addLinks()}
            </ul>
          </div>
          <div className="nav-filter">
            <div className="nav-wrapper">
              <div className="logo">
                <img src={SimplyServeSmall} alt="logo" />
              </div>
              <div className="links">
                <ul>{this.addLinks()}</ul>
              </div>
              <div
                className={
                  this.state.mobileNav ? "burger-menu active" : "burger-menu"
                }
                onClick={() =>
                  this.setState({
                    mobileNav: this.state.mobileNav ? false : true,
                  })
                }
              >
                <MenuRoundedIcon />
              </div>
              <div className="right">
                <a className="log-in" href="https://app.simplyserve.io">
                  Log in
                </a>
                <Link to="/demo">Book Demo</Link>
              </div>
            </div>
          </div>

          <Switch>
            <Route path="/" exact>
              <Home maxWidth={maxWidth} />
            </Route>
            <Route path="/trial" exact>
              <Trial maxWidth={maxWidth} />
            </Route>
            <Route path="/trial-sign-up" exact>
              <TrialSignUp maxWidth={maxWidth} />
            </Route>
            <Route path="/ourstory" exact>
              <About maxWidth={maxWidth} />
            </Route>
            <Route path="/value" exact>
              <Value maxWidth={maxWidth} />
            </Route>
            <Route path="/features" exact>
              <FeaturesPage maxWidth={maxWidth} />
            </Route>
            <Route path="/pricing" exact>
              <Pricing maxWidth={maxWidth} />
            </Route>
            <Route path="/faq" exact>
              <FAQs maxWidth={maxWidth} />
            </Route>
            <Route path="/contact" exact>
              <Contact maxWidth={maxWidth} />
            </Route>
            <Route path="/demo" exact>
              <Demo maxWidth={maxWidth} />
            </Route>
            <Route path="/terms" exact>
              <Terms maxWidth={maxWidth} />
            </Route>
            <Route path="/privacy" exact>
              <Privacy maxWidth={maxWidth} />
            </Route>
            <Route path="/">
              <PageNotFound />
            </Route>
          </Switch>

          <div className="footer-wrapper">
            <div className="left">
              <ul>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>

                <li>
                  <Link to="/">Training</Link>
                </li>

                <li>
                  <Link to="/privacy">Privacy</Link>
                </li>

                <li>
                  <Link to="/terms">Terms of Service</Link>
                </li>
              </ul>
            </div>
            <div className="right">
              <Link to="/">Careers</Link>
              <p>
                We're not currently hiring for specific positions, but we are
                always on the lookout for awesome people.
              </p>

              <p>
                From first job to senior consulting, if you're awesome and you
                think we are too, do get in touch. We'd love to hear from you!
              </p>
            </div>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;

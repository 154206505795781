import React, { Component } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import SimplyServeSmall from "../images/SimplyServeSmall.png";
import ReactGA from "react-ga";
import "../css/contact.css";

class Contact extends Component {
  state = {
    formData: {
      name: "",
      email: "",
      phone: "",
      message: null,
    },
    submitting: false,
    message: "",
  };
  constructor(props) {
    super(props);

    this.recaptchaRef = React.createRef();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  submitForm = async (event) => {
    event.preventDefault(0);
    this.setState({ submitting: true });
    await this.postSubmission();
    this.setState({ submitting: false });
  };

  postSubmission = async () => {
    const formId = "bDjrBTiD";
    const formSparkUrl = "https://submit-form.com/" + formId;
    const payload = {
      ...this.state.formData,
      "g-recaptcha-response": this.state.recaptchaToken,
    };

    try {
      const result = await axios.post(formSparkUrl, payload);
      this.setState({
        message: {
          class: "green",
          message: "Thanks, someone will be in touch shortly.",
        },
        formData: {
          name: "",
          email: "",
          phone: "",
          message: null,
        },
      });
      this.recaptchaRef.current.reset();
    } catch (err) {
      this.setState({
        message: {
          class: "red",
          message: "Sorry, there was a problem. Please try again.",
        },
      });
    }
  };

  updateFormControl = (event) => {
    const { id, value } = event.target;
    const formKey = id;
    const updateFormState = { ...this.state.formData };
    updateFormState[formKey] = value;
    this.setState({ formData: updateFormState });
  };

  updateRecaptchaToken = (token) => {
    this.setState({ recaptchaToken: token });
  };

  render() {
    const recaptchaKey = process.env.REACT_APP_ReCAPTCHA_KEY;

    return (
      <div className="page contact">
        <div className="page-title">
          SimplyServe
          <img src={SimplyServeSmall} alt="logo" />
          <div className="page-sub-title">get in touch</div>
        </div>
        <div className="message-container">
          <div className="message">
            <div className="content">
              <h2>get in touch</h2>
              {this.state.message && (
                <div className={this.state.message.class}>
                  {this.state.message.message}
                </div>
              )}
              <form onSubmit={this.submitForm}>
                <label for="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Name"
                  required=""
                  value={this.state.formData.name}
                  onChange={this.updateFormControl}
                />
                <label for="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  required=""
                  value={this.state.formData.email}
                  onChange={this.updateFormControl}
                />
                <label for="phone">Phone</label>
                <input
                  type="number"
                  id="phone"
                  name="phone"
                  placeholder="Phone"
                  required=""
                  value={this.state.formData.phone}
                  onChange={this.updateFormControl}
                />
                <label for="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Message"
                  required=""
                  onChange={this.updateFormControl}
                >
                  {this.state.formData.message}
                </textarea>
                <ReCAPTCHA
                  ref={this.recaptchaRef}
                  sitekey={recaptchaKey}
                  onChange={this.updateRecaptchaToken}
                />
                <button type="submit" disabled={this.state.submitting}>
                  {this.state.submitting ? "Sending..." : "Send"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;

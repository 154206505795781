import React, { Component } from "react";
import SimplyServeSmall from "../images/SimplyServeSmall.png";
import ReactGA from "react-ga";
import Phone from "../components/host/phone";
import Quote from "../components/quote";
import { Link } from "react-router-dom";
import "../css/trial.css";

class Trial extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  };

  render() {
    return (
      <div className="page trial">
        {/* <div className="page-title">
      Pricing
      <div className="page-sub-title">keep it simple</div>
    </div> */}
        <div className="page-title">
          SimplyServe
          <img src={SimplyServeSmall} alt="logo" />
          <div className="page-sub-title">100 Day Free Trial</div>
        </div>
        <div className="message-container">
          <div className="message">
            <div
              className="content"
              style={{
                width: this.props.maxWidth - 20 + "px",
                padding: "10px",
              }}
            >
              <h2>100 Day Free Trial</h2>
              <p>
                Absolutely zero obligation. No credit card required. Simply 100
                days to find out if you prefer a smart booking system to pen
                &#038; paper.
              </p>
              <p className="cta" style={{ marginBottom: "70px" }}>
                <h2>Your Trial</h2>Host by SimplyServe gets a lot done. With the
                free trial you get full access to all our features. We'll even
                set the system up for you and deliver a training session so you
                hit the ground the running.
                <br />
                <center>
                  <Link to="/trial-sign-up">Sign up now</Link>
                </center>
              </p>
              <Quote text="We used to have staff come in early to organise the bookings. Now Host does it all for us." />

              <div className="feature">
                <p>
                  Imagine a small, fast-paced restaurant. Just 5 tables &#038;
                  10 bookings for lunch today.
                </p>
                <p>
                  {" "}
                  Each booking is given 1h30m. Check these bookings on the iPad
                  below, can they take a new booking at 1430?
                </p>

                <Phone maxWidth={this.props.maxWidth} />

                <p>
                  Not only can you take this booking, but if you offered them 15
                  minutes earlier or 15 minutes less on the table then you can
                  keep a whole table free for more customers.
                </p>
                <p>
                  Host can organise the bookings, find &#038; present these 3
                  options to your staff in less than a tenth of a second.
                </p>
              </div>

              <p>
                When signing up to the trial we'll tell you everything you need
                to know. But if you'd like to see that first you can book a{" "}
                <Link to="/demo">demo</Link> or explore our{" "}
                <Link to="/features">features</Link>.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Trial;

import React, { Component } from "react";
import FAQ from "../components/faq";
import SimplyServeSmall from "../images/SimplyServeSmall.png";
import ReactGA from "react-ga";
import "../css/faq.css";

class FAQs extends Component {
  state = {
    selected: null,
    FAQs: [
      {
        question: "Love it. How do I sign up?",
        answer: (
          <React.Fragment>
            <p>Great! Please start off by booking a demo.</p>
            <p>
              It's not a self-signup process simply because there's a lot of
              personal touches. We want to make sure you get off to a fantastic
              start so we will fully on-board you.
            </p>
            <p>
              What does this on-boarding include? Well, we will fully setup your
              account with your hours, table plans, and various other settings.
            </p>
            <p>
              Of course we will fully train you on how to use the software, and
              we'll even book in separate sessions for your team so we can train
              them too. Ideally we do all this training face-to-face. We come to
              you.
            </p>
            <p>
              It doesn't end there. We don't just put your restaurant live and
              ghost you, check the question on support further down.
            </p>
          </React.Fragment>
        ),
        height: 0,
        id: 1,
      },
      {
        question: "Are there setup fees or training costs?",
        answer: (
          <React.Fragment>
            <p>No.</p>
            <p>
              We want 100% of our partner restaurants to succeed with
              SimplyServe. If you, or your team, need further training we are
              always happy to help out.
            </p>
          </React.Fragment>
        ),
        height: 0,
        id: 2,
      },
      {
        question: "Do you do trial periods?",
        answer: (
          <React.Fragment>
            <p>If you need one, absolutely.</p>
            <p>Just book a demo and we'll chat it through.</p>
          </React.Fragment>
        ),
        height: 0,
        id: 3,
      },
      {
        question:
          "What does your support and on-going customer management look like?",
        answer: (
          <React.Fragment>
            <p>It's pretty awesome, actually.</p>
            <p>
              Once you're live we regularly check in (unless you'd prefer we
              didn't!) for the first 3 months. Just to make sure all is going
              great.
            </p>
            <p>
              But if you need to reach out to us there's no "fill in a web
              ticket and hope". You have an Account Manager, their phone number
              and email address. If they're away we'll route you through to
              someone else. This is for any and all day-to-day questions and
              requirements.
            </p>
            <p>
              We also provide you with an out-of-hours email address and phone
              number, which is available 24/7. We just ask that this is only
              used if you absolutely cannot wait until the next working day.
            </p>
          </React.Fragment>
        ),
        height: 0,
        id: 4,
      },
      {
        question:
          "I've had my website redone. Can you restyle the booking widget to match?",
        answer: (
          <React.Fragment>
            <p>Yes.</p>
            <p>
              This does come at a cost to us, so we will do it free of charge
              once per subscription year.
            </p>
          </React.Fragment>
        ),
        height: 0,
        id: 5,
      },
      {
        question: "Do I need and iPad to run Host?",
        answer: (
          <React.Fragment>
            <p>
              We certainly recommend an iPad because they're very consistent,
              whereas all Android tablets are different, but Host will run on
              any tablet.
            </p>
            <p>
              In fact, Host runs on any computer or phone, but it's designed for
              a touch screen of reasonable size (a tablet) to be able to give a
              professional customer facing experience.
            </p>
            <p>
              But equally you can leave the tablet with the host and log in on
              the back office computer, answer the phone, and take bookings
              there too.
            </p>
          </React.Fragment>
        ),
        height: 0,
        id: 6,
      },
      {
        question: "Can I run Host on my mobile?",
        answer: (
          <React.Fragment>
            <p>Mostly. You can do the day-to-day bits.</p>
            <p>
              More detail: We're improving the mobile optimisation all the time,
              but you can certainly browse your calendar, browse bookings, edit
              bookings, add new ones.
            </p>
            <p>
              We wouldn't currently recommend running a busy shift from your
              phone, and you won't be able to use a mobile to build your
              restaurant table plans and schedules (yet!).
            </p>
          </React.Fragment>
        ),
        height: 0,
        id: 7,
      },
    ],
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);

    const FAQs = this.state.FAQs;
    FAQs.forEach(
      (f) => (f.height = document.getElementById(f.id).offsetHeight)
    );
    this.setState({ FAQs });
  };

  handleExpand = (id) => {
    this.setState({ selected: id });
  };

  render() {
    return (
      <div className="page faqs">
        {/* <div className="page-title">
      Pricing
      <div className="page-sub-title">keep it simple</div>
    </div> */}
        <div className="page-title">
          SimplyServe
          <img src={SimplyServeSmall} alt="logo" />
          <div className="page-sub-title">frequently asked questions</div>
        </div>
        <div className="message-container">
          <div className="message">
            <div
              className="content"
              style={{
                width: this.props.maxWidth - 20 + "px",
                padding: "10px",
              }}
            >
              <h2>FAQs</h2>
              {this.state.FAQs.map((f) => (
                <FAQ
                  key={f.id}
                  expand={this.state.selected}
                  question={f.question}
                  answer={f.answer}
                  id={f.id}
                  height={f.height}
                  onExpand={this.handleExpand}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FAQs;

import React, { Component } from "react";
class FAQ extends Component {
  render() {
    return (
      <div
        className="faq"
        onClick={() =>
          this.props.onExpand(
            this.props.id === this.props.expand ? null : this.props.id
          )
        }
      >
        <div className="question">{this.props.question}</div>
        <div
          style={{
            height:
              this.props.expand === this.props.id
                ? this.props.height + "px"
                : "0",
          }}
          className="answer-wrapper"
        >
          <div className="answer" id={this.props.id}>
            {this.props.answer}
          </div>
        </div>
      </div>
    );
  }
}

export default FAQ;

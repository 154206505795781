import React, { Component } from "react";
import FormatQuoteRoundedIcon from "@material-ui/icons/FormatQuoteRounded";
import "../css/quote.css";

class Quote extends Component {
  render() {
    return (
      <div className="quote">
        {this.props.text}
        <FormatQuoteRoundedIcon />

        <FormatQuoteRoundedIcon className="right" />
      </div>
    );
  }
}

export default Quote;

import React, { Component } from "react";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import "../css/datepicker.css";
class DatePicker extends Component {
  state = { display: [], date: new Date() };

  forwardMonth = () => {
    //grab current date and add a month. Must change current date to first day of the month first or 2 months could get jumped (30 Jan +1 month = 30 Feb = 2 March)
    let date = new Date(this.state.date);
    date = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    this.setState({ date });
    this.buildCalendar(date);
  };
  backMonth = () => {
    //grab current date and add a month. Must change current date to first day of the month first or 2 months could get jumped (30 Jan +1 month = 30 Feb = 2 March)
    let date = new Date(this.state.date);
    date = new Date(
      this.state.date.getFullYear(),
      this.state.date.getMonth() - 1,
      1
    );
    this.setState({ date });
    this.buildCalendar(date);
  };
  componentDidMount = () => {
    let calDate = new Date();
    calDate = new Date(calDate.setHours(0, 0, 0, 0));
    if (this.props.selectedDate) {
      calDate = this.props.selectedDate;
    }
    this.buildCalendar(calDate);
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.selectedDate !== this.props.selectedDate) {
      let calDate = new Date();
      calDate = new Date(calDate.setHours(0, 0, 0, 0));
      if (this.props.selectedDate) {
        calDate = this.props.selectedDate;
      }
      this.buildCalendar(calDate);
    }
  };

  buildCalendar = (givenDate) => {
    //givenDate is mandatory and should be midnight: *new Date(date.sethours(0,0,0,0)*)

    let date = new Date(givenDate);

    //grab the month
    const month = date.getMonth();
    // get the first & lastday of the month
    let firstDayOfMonth = new Date(date);
    firstDayOfMonth = new Date(
      firstDayOfMonth.getFullYear(),
      firstDayOfMonth.getMonth(),
      1
    );
    let lastDayOfMonth = new Date(date);
    lastDayOfMonth = new Date(
      lastDayOfMonth.getFullYear(),
      lastDayOfMonth.getMonth() + 1,
      0
    );

    //get the day of the week that is the first day of the month (0-6), and grab the Monday from that week. This Monday is the startDate of the calendar.
    let firstDayOfWeek = new Date(firstDayOfMonth);
    firstDayOfWeek = firstDayOfWeek.getDay();
    let stepsBack = firstDayOfWeek;
    stepsBack === 0 && (stepsBack = 7);

    let startDate = new Date(firstDayOfMonth);
    startDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      2 - stepsBack
    );
    // create an array (the month to display) of arrays (the weeks) of objects (the days).
    const allDates = [];
    let currentDate = new Date(startDate);
    // x is the id of the individual calendar dates to display. Will be used as the key. i is to make lists of 7 days.
    let x = 0;
    // while we havent reached the end of the month, create a new week for the calendar to display
    while (currentDate < lastDayOfMonth) {
      let i = 0;
      let newWeek = [];
      //while we havent made 7 days, create day objects. id, date, differentMonth & today (for styling). To add optionally: schedules (all); or schedule & covers by service (break lunch dinner)
      while (i < 7) {
        let differentMonth = false;
        if (currentDate.getMonth() !== month) {
          differentMonth = true;
        }
        let today = "future";
        let todayDate = givenDate;
        this.props.firstAvailable && (todayDate = this.props.firstAvailable);
        todayDate = new Date(todayDate.setHours(0, 0, 0, 0));
        if (currentDate.valueOf() === todayDate.valueOf()) {
          today = "today";
        }
        if (currentDate.valueOf() < todayDate.valueOf()) {
          today = "past";
        }
        if (currentDate.valueOf() === this.props.selectedDate.valueOf()) {
          today = today + " selected";
        }

        // cant use currentDate as the object stores a reference to the date, which is incremented. At the end all dates will be the same.
        let bookingDate = new Date(currentDate);
        const day = {
          id: x,
          date: bookingDate,
          differentMonth,
          today,
        };
        newWeek.push(day);
        currentDate.setDate(currentDate.getDate() + 1);
        i++;
        x++;
      }
      allDates.push(newWeek);
    }
    this.setState({ display: allDates, date });
  };
  render() {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const style = this.props.style;
    let cell = {};
    let cellFaded = {};
    let cellHighlight = {};

    if (style) {
      cell = {
        border: "1px solid " + style.background,
        background: style.cell,
      };
      cellFaded = {
        border: "1px solid " + style.background,
        background: style.cellFaded,
      };
      cellHighlight = {
        border: "1px solid " + style.background,
        background: style.accent,
      };
    }

    let datePicker = (
      <div
        className="calendar-overlay date-picker-container blackout"
        onClick={() => this.props.onNewDate(this.props.selectedDate)}
      >
        <div
          className="date-picker"
          onClick={(e) => e.stopPropagation()}
          style={{
            background: style ? style.background : "transparent",
            width: "100%",
          }}
        >
          <table style={{ width: "100%" }}>
            <thead style={style ? { background: style.background } : {}}>
              <tr>
                <th colSpan="7">
                  <h2 style={{ display: "inline-block", cursor: "pointer" }}>
                    <ArrowBackIosRoundedIcon
                      onClick={this.backMonth}
                      style={style ? { color: style.accent } : {}}
                    />
                  </h2>
                  <h2 style={{ display: "inline-block" }} className="h2-month">
                    {monthNames[this.state.date.getMonth()]}
                    &nbsp;{this.state.date.getFullYear()}
                  </h2>
                  <h2 style={{ display: "inline-block", cursor: "pointer" }}>
                    <ArrowForwardIosRoundedIcon
                      onClick={this.forwardMonth}
                      style={style ? { color: style.accent } : {}}
                    />
                  </h2>
                </th>
              </tr>
              <tr>
                <th>M</th>
                <th>T</th>
                <th>W</th>
                <th>T</th>
                <th>F</th>
                <th>S</th>
                <th>S</th>
              </tr>
            </thead>
            <tbody>
              {/* Iterate through weeks for rows, then days for cells */}
              {this.state.display.map((week) => (
                <tr key={this.state.display.indexOf(week)}>
                  {week.map((day) => (
                    <td
                      key={day.id}
                      className={
                        (day.differentMonth ? "different-month " : "") +
                        day.today
                      }
                      onClick={this.props.onNewDate.bind(this, day.date)}
                      style={
                        day.today
                          ? cellHighlight
                          : (day.differentMonth && cellFaded) || cell
                      }
                    >
                      <h3>{new Date(day.date).getDate()}</h3>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
    return datePicker;
  }
}

export default DatePicker;

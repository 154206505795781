import React, { Component } from "react";
import SimplyServeSmall from "../images/SimplyServeSmall.png";
import ReactGA from "react-ga";

class Privacy extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  };
  render() {
    return (
      <div className="page terms">
        {/* <div className="page-title">
      Pricing
      <div className="page-sub-title">keep it simple</div>
    </div> */}
        <div className="page-title">
          SimplyServe
          <img src={SimplyServeSmall} alt="logo" />
          <div className="page-sub-title">Terms of Service</div>
        </div>
        <div className="message-container">
          <div className="message">
            <div
              className="content"
              style={{
                width: this.props.maxWidth - 20 + "px",
                padding: "10px",
              }}
            >
              <h2>Privacy Policy</h2>

              <p>
                We are committed to protecting and respecting your privacy, so
                below you will find full details of how we keep your personal
                data safe.
              </p>
              <p>
                This privacy policy will inform you as to how we look after your
                personal data when you visit our website or sign up to and use
                our service. It will also tell you about your privacy rights and
                how the law protects you.
              </p>
              <p>
                Please read the following carefully to understand our views and
                practices regarding your personal data and how we will treat it.
              </p>
              <h4>Who we are</h4>

              <p>
                Simply Serve Somerset Ltd is a limited liability company
                incorporated in England and Wales. Our company number is
                13618439, and our registered office is at Unit 5 Ducketts Wharf,
                South Street, Bishop's Stortford, England, CM23 3AR.
              </p>
              <p>
                We trade under the name SimplyServe. When we refer to this
                trading name, or “we”, “us” or “our“ in this policy, we are
                referring to Simply Serve Somerset Ltd. We are the controller
                and responsible for your personal data.
              </p>
              <p>
                Our service helps you to manage your customer reservations and
                walk-in availability. We help your staff make smart decisions,
                quickly, with confidence.
              </p>
              <h4>Purpose of this privacy policy</h4>

              <p>
                This privacy policy aims to give you information on how we
                collect and process your personal data when you use our website
                or service.
              </p>
              <p>
                This website is not intended for children and we do not
                knowingly collect data relating to children.
              </p>
              <p>
                It is important that you read this privacy policy together with
                any other privacy notice or fair processing notice we may
                provide on specific occasions when we are collecting or
                processing personal data about you, so that you are fully aware
                of how and why we are using your data. This privacy policy
                supplements the other notices and is not intended to override
                them.
              </p>
              <h4>Third-party links</h4>

              <p>
                Our website may include links to third-party websites, plug-ins
                and applications. Clicking on those links or enabling those
                connections may allow third parties to collect or share data
                about you. We do not control these third-party websites and are
                not responsible for their privacy statements. When you leave one
                of our websites, we encourage you to read the privacy notice of
                every website you visit.
              </p>
              <h4>Information we may collect from you</h4>

              <p>
                We use different methods to collect data from and about you
                including through:
              </p>
              <ul>
                <li>
                  Direct interactions. You may give us your personal data by
                  filling in forms or by corresponding with us in person, by
                  email, by conference call or otherwise. This includes personal
                  data you provide when signing up to our service and when you
                  request marketing communications to be sent to you, or opt-in
                  to attend a webinar or download an e-book.
                </li>
                <li>
                  Third parties or publicly available sources. We may receive
                  personal data about you from various third parties and public
                  sources, for example, if you sign-up to our service via a
                  social media platform such as Facebook.
                </li>
              </ul>
              <p>We may collect and process the following data about you:</p>
              <ul>
                <li>
                  Sign-up Data. This includes your name, email address and any
                  other information provided at the time of registering to use
                  our site or subscribing to our service.
                </li>
                <li>
                  Correspondence Data. If you{" "}
                  <a href="https://simplyserve.io/contact">contact us</a>, we
                  may keep a record of that correspondence.
                </li>
                <li>
                  Marketing and Communications Data. This includes name, company
                  name, email address, preferences in relation to receiving
                  marketing materials from us.
                </li>
                <li>
                  Survey Data. We may also ask you to complete surveys that we
                  use for research purposes, although you do not have to respond
                  to them.
                </li>
                <li>
                  Transaction Data. Details of transactions you carry out
                  through our site and of the fulfilment of your orders.
                </li>
                <li>
                  Marketing Analytics. Details of your visits to our site
                  including, but not limited to, traffic data, location data,
                  weblogs and other communication data, whether this is required
                  for our own billing purposes or otherwise and the resources
                  that you access.
                </li>
                <li>
                  Payment Confirmation Data. Where you place an order with us or
                  otherwise purchase our services, confirmation of your payment
                  (but we shall not hold your credit card details as we use a
                  third party payment service provider).
                </li>
                <li>
                  IP Address Data. We may collect information about your
                  computer, including where available your IP address, operating
                  system and browser type, for system administration and to
                  report aggregate information to our advertisers. This is
                  statistical data about our users’ browsing actions and
                  patterns, and does not identify any individual.
                </li>
              </ul>
              <h4>Uses made of the information</h4>

              <p>
                We will only use your personal data when the law allows us to.
                Most commonly, we will use your personal data in the following
                circumstances.
              </p>
              <p>We use information held about you:</p>
              <ul>
                <li>
                  To ensure that content from our site is presented in the most
                  effective manner for you and for your computer.
                </li>
                <li>
                  To provide you with information, products or services that you
                  request from us or which we feel may interest you, where you
                  have consented to be contacted for such purposes.
                </li>
                <li>
                  To carry out our obligations arising from any contracts
                  entered into between you and us.
                </li>
                <li>
                  To allow you to participate in interactive features of our
                  service, when you choose to do so.
                </li>
                <li>To notify you about changes to our service.</li>
                <li>
                  To process your payment card details in order to complete any
                  purchase that you make through our site.
                </li>
              </ul>
              <p>
                If you are an existing customer, we will only contact you by
                electronic means (e-mail) with information about goods and
                services similar to those which were the subject of a previous
                sale to you.
              </p>
              <p>
                If you are a new customer, and where we permit selected third
                parties to use your data, we (or they) will contact you by
                electronic means only if you have consented to this.
              </p>
              <p>
                If you do not want us to use your data in this way, or to pass
                your details on to third parties for marketing purposes, please
                get in touch with{" "}
                <a href="mailto:support@simplyserve.io">
                  support@simplyserve.io
                </a>
                .
              </p>
              <h4>Disclosure of your information</h4>

              <p>
                We may have to share your personal information with the
                following third parties:
              </p>
              <ul>
                <li>
                  External service providers acting as processors who provide
                  the following services:
                </li>
                <li>Web hosting;</li>
                <li>Metrics and analytics;</li>
                <li>Customer relationship management;</li>
                <li>Payment processing;</li>
                <li>Billing management; and</li>
                <li>Net promotion services;</li>
                <li>Advertising and marketing services.</li>
              </ul>
              <p>
                Please note this includes the international service providers
                listed below in International transfers.
              </p>
              <ul>
                <li>
                  Professional advisers, including lawyers, bankers, auditors
                  and insurers who provide consultancy, banking, legal,
                  insurance and accounting services.
                </li>
                <li>
                  HM Revenue &#038; Customs, regulators and other authorities
                  acting as processors or joint controllers based who require
                  reporting of processing activities in certain circumstances.
                  This includes exchanging information with other companies and
                  organisations for the purposes of fraud protection and credit
                  risk reduction.
                </li>
                <li>
                  In the event that we sell or buy any business or assets, in
                  which case we may disclose your personal data to the
                  prospective seller or buyer of such business or assets.
                </li>
                <li>
                  If Simply Serve Somerset Ltd or substantially all of its
                  assets are acquired by a third party, in which case personal
                  data held by it about its customers will be one of the
                  transferred assets.
                </li>
              </ul>
              <p>
                We require all third parties to respect the security of your
                personal data and to treat it in accordance with the law. We do
                not allow our third-party service providers to use your personal
                data for their own purposes and only permit them to process your
                personal data for specified purposes and in accordance with our
                instructions.
              </p>
              <h4>International transfers</h4>

              <p>
                If we transfer your personal data out of the EEA, we ensure a
                similar degree of protection is afforded to it by ensuring at
                least one of the following safeguards is implemented:
              </p>
              <ul>
                <li>
                  The country has been deemed to provide an adequate level of
                  protection for personal data by the European Commission. For
                  further details, see{" "}
                  <em>
                    European Commission:{" "}
                    <a href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en">
                      Adequacy of the protection of personal data in non-EU
                      countries.
                    </a>
                  </em>
                </li>
                <li>
                  If we use certain service providers based outside the EEA, we
                  may use specific contracts approved by the European Commission
                  which give personal data the same protection it has in Europe.
                  For further details, see{" "}
                  <em>
                    <a href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en">
                      European Commission: Model contracts for the transfer of
                      personal data to third countries.
                    </a>
                  </em>
                </li>
                <li>
                  If we use providers based in the US, we may transfer data to
                  them if they are part of the Privacy Shield which requires
                  them to provide similar protection to personal data shared
                  between the Europe and the US. For further details, see{" "}
                  <em>
                    <a href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/eu-us-privacy-shield_en">
                      European Commission: EU-US Privacy Shield
                    </a>
                    .
                  </em>
                </li>
                <li>
                  In any other case, we will obtain your explicit consent before
                  any transfer takes place.
                </li>
              </ul>
              <p>
                Please <a href="https://www.simplyserve.io">Contact us</a> if
                you want further information on the specific mechanism used by
                us when transferring your personal data out of the EEA.
              </p>
              <h4>Data security</h4>

              <p>
                We have put in place appropriate security measures to prevent
                your personal data from being accidentally lost, used or
                accessed in an unauthorised way, altered or disclosed. In
                addition, we limit access to your personal data to those
                employees, agents, contractors and other third parties who have
                a business need to know. They will only process your personal
                data on our instructions and they are subject to a duty of
                confidentiality.
              </p>
              <p>
                All information you provide to us is stored on secure servers.
                Any payment transactions will be encrypted using SSL technology
                and using trusted third parties. Where we have given you (or
                where you have chosen) a password which enables you to access
                certain parts of our site, you are responsible for keeping this
                password confidential. We ask you not to share a password with
                anyone.
              </p>
              <p>
                Unfortunately, the transmission of information via the internet
                is not completely secure. Although we will do our best to
                protect your personal data, we cannot guarantee the security of
                your data transmitted to our site; any transmission is at your
                own risk. Once we have received your information, we will use
                strict procedures and security features to try to prevent
                unauthorised access.
              </p>
              <h4>How long we store your personal data for</h4>

              <p>
                We will only retain your personal data for as long as necessary
                to fulfil the purposes we collected it for, including for the
                purposes of satisfying any legal, accounting, or reporting
                requirements.
              </p>
              <p>
                To determine the appropriate retention period for personal data,
                we consider the amount, nature, and sensitivity of the personal
                data, the potential risk of harm from unauthorised use or
                disclosure of your personal data, the purposes for which we
                process your personal data and whether we can achieve those
                purposes through other means, and the applicable legal
                requirements.
              </p>
              <p>
                We normally delete personal identifiable information we hold on
                you within 12 months of you unsubscribing but in some
                circumstances you can ask us to delete your data sooner: see
                Deleting personal data below for further information.
              </p>
              <p>
                Where we anonymise your personal data (i.e. so that it can no
                longer be associated with you) for further research or
                statistical purposes, then we may use this information
                indefinitely without further notice to you.
              </p>
              <h4>Your rights</h4>

              <p>
                Under certain circumstances, you have rights under data
                protection laws in relation to your personal data.
              </p>
              <ul>
                <li>Access to your information</li>
                <li>Request correction of your personal data</li>
                <li>Request deletion of your personal data</li>
                <li>Object to processing of your personal data</li>
                <li>Request restriction of processing your personal data</li>
                <li>Request transfer of your personal data</li>
                <li>Right to withdraw consent</li>
                <li>Right to review by an independent authority</li>
              </ul>
              <p>
                If you wish to exercise any of the rights set out above, please{" "}
                <a href="https://simplyserve.io/contact">Contact us</a>. You
                will not have to pay a fee to access your personal data (or to
                exercise any of the other rights). However, we may charge a
                reasonable fee if your request is clearly unfounded, repetitive
                or excessive. Alternatively, we may refuse to comply with your
                request in these circumstances.
              </p>
              <p>
                We may need to request specific information from you to help us
                confirm your identity and ensure your right to access your
                personal data (or to exercise any of your other rights). This is
                a security measure to ensure that personal data is not disclosed
                to any person who has no right to receive it. We may also
                contact you to ask you for further information in relation to
                your request to speed up our response.
              </p>
              <p>
                We try to respond to all legitimate requests within one month.
                Occasionally it may take us longer than a month if your request
                is particularly complex or you have made a number of requests.
                In this case, we will notify you and keep you updated.
              </p>
              <h4>Access to your information</h4>

              <p>
                You have the right to ask for a copy of the information which we
                hold on you (commonly known as a ‘data subject access request’).
              </p>
              <p>
                This enables you to receive a copy of the personal data we hold
                about you and to check that we are lawfully processing it.
              </p>
              <h4>Correcting personal data</h4>

              <p>
                You have the right to request that we correct personal data that
                we hold about you. This enables you to have any incomplete or
                inaccurate information we hold corrected, though we may need to
                verify the accuracy of the new data that you provide to us.
              </p>
              <h4>Deleting personal data</h4>

              <p>
                You may ask us to delete or remove personal data where there is
                no good reason for us continuing to process it. This is more
                commonly known as the ‘right to be forgotten’. You also have the
                right to ask us to delete or remove your personal data where you
                have successfully exercised your right to object to processing
                (see below), where we may have processed your information
                unlawfully or where we are required to erase your personal data
                to comply with local law.
              </p>
              <p>
                Please note, however, that we may not always be able to comply
                with your request to delete or remove personal data for specific
                legal reasons which will be notified to you, if applicable, at
                the time of your request.
              </p>
              <h4>Objecting to processing</h4>

              <p>
                You have the right to stop us processing your personal data for
                direct marketing purposes. We will always inform you if we
                intend to use your personal data for such purposes, or if we
                intend to disclose your information to any third party for such
                purposes. You can also exercise the right at any time by
                contacting us at{" "}
                <a href="mailto:support@simplyserve.io">
                  support@simplyserve.io
                </a>
              </p>
              <p>
                You may also object to us processing your personal data where we
                are relying on a legitimate interest (or those of a third party)
                and there is something about your particular situation which
                makes you want to object to processing on this ground as you
                feel it impacts on your fundamental rights and freedoms. In some
                cases, we may demonstrate that we have compelling legitimate
                grounds to process your information which override your rights
                and freedoms.
              </p>
              <h4>Restriction of processing</h4>

              <p>
                This enables you to ask us to suspend the processing of your
                personal data in the following scenarios:
              </p>
              <ul>
                <li>if you want us to establish the data’s accuracy;</li>
                <li>
                  where our use of the data is unlawful but you do not want us
                  to erase it;
                </li>
                <li>
                  where you need us to hold the data even if we no longer
                  require it as you need it to establish, exercise or defend
                  legal claims; or
                </li>
                <li>
                  you have objected to our use of your data but we need to
                  verify whether we have overriding legitimate grounds to use
                  it.
                </li>
              </ul>
              <h4>Transferring your personal data</h4>

              <p>
                In certain circumstances, you may request the transfer of your
                personal data to you or to a third party. We will provide to
                you, or a third party you have chosen, your personal data in a
                structured, commonly used, machine-readable format.1
              </p>
              <p>
                Please note that this right only applies to automated
                information which you initially provided consent for us to use
                or where we used the information to perform a contract with you.
              </p>
              <h4>Withdrawing consent</h4>

              <p>
                Where we are relying on consent to process your personal data
                you can withdraw your consent at any time. Please note that this
                will not affect the lawfulness of any processing carried out
                before you withdraw your consent.
              </p>
              <h4>Review by an independent authority</h4>

              <p>
                You will always have the right to lodge a complaint with a
                supervisory body. The relevant authority in the UK is the
                Information Commissioner’s Office. If you do have a complaint,
                we would appreciate the chance to deal with your concerns before
                you approach the ICO, so please{" "}
                <a href="https://www.simplyserve.io/contact">contact us</a> in
                the first instance if possible.
              </p>
              <h4>Changes to our privacy policy</h4>

              <p>This version was last updated on 21st Sep 2021.</p>
              <p>
                It is important that the personal data we hold about you is
                accurate and current. Please keep us informed if your personal
                data changes during your relationship with us.
              </p>
              <h4>Contact us</h4>

              <p>
                Questions, comments and requests regarding this privacy policy
                are welcomed and should be addressed to{" "}
                <a href="mailto:privacy@simplyserve.io">
                  privacy@simplyserve.io
                </a>
                .
              </p>
              <h4>LAWFUL BASIS</h4>

              <p>
                We have set out below, in a table format, a description of all
                the ways we plan to use your personal data, and which of the
                legal bases we rely on to do so. We have also identified what
                our legitimate interests are where appropriate.
              </p>
              <p>
                Please note that we may process your personal data for more than
                one lawful ground depending on the specific purpose for which we
                are using your data. Please contact us at{" "}
                <a href="mailto:support@simplyserve.io">
                  support@simplyserve.io
                </a>{" "}
                if you need details about the specific legal ground we are
                relying on to process your personal data where more than one
                ground has been set out in the list below.
              </p>
              <p>PURPOSE/ACTIVITY</p>
              <p>TYPE OF DATA</p>
              <p>LAWFUL BASIS FOR PROCESSING</p>
              <p>To sign you up to receive our services.</p>
              <p>Sign-up Data</p>
              <p>Performance of a contract with you</p>
              <p>Direct interactions with our team</p>
              <p>Correspondence data</p>
              <p>Performance of a contract with you</p>
              <p>
                Legitimate interest (to engage with and assist our customers)
              </p>
              <p>
                Providing you with educational resources such as newsletters,
                guides and webinars
              </p>
              <p>Marketing and Communications Data</p>
              <p>Consent</p>
              <p>Capturing feedback from you about our product and services</p>
              <p>Survey data</p>
              <p>Consent</p>
              <p>Billing and renewal details</p>
              <p>Transaction Data</p>
              <p>Performance of a contract with you</p>
              <p>
                Capturing data about your usage of the product, and your
                activity on our site
              </p>
              <p>Marketing analytics</p>
              <p>
                Legitimate interest (to analyse and improve our product/service
                offering)
              </p>
              <p>Consent</p>
              <p>Confirmation of your billing history</p>
              <p>Payment Confirmation Data</p>
              <p>Performance of a contract with you</p>
              <p>Understanding usage patterns</p>
              <p>IP Address</p>
              <p>
                Legitimate interest (to analyse and improve our product/service
                offering)
              </p>
              <h4>GLOSSARY</h4>

              <p>
                Legitimate Interest means our interest in conducting and
                managing our business to enable us to give you the best
                service/product and the best and most secure experience. We make
                sure we consider and balance any potential impact on you (both
                positive and negative) and your rights before we process your
                personal data for our legitimate interests. We do not use your
                personal data for activities where our interests are overridden
                by the impact on you (unless we have your consent or are
                otherwise required or permitted to by law). You can obtain
                further information about how we assess our legitimate interests
                against any potential impact on you in respect of specific
                activities by{" "}
                <a href="https://www.simplyserve.io">contacting us</a>.
              </p>
              <p>
                Performance of Contract means processing your data where it is
                necessary for the performance of a contract to which you are a
                party or to take steps at your request before entering into such
                a contract.
              </p>
              <p>
                Complying with a legal or regulatory obligation means processing
                your personal data where it is necessary for compliance with a
                legal or regulatory obligation that we are subject to.
              </p>
              <p>
                Consent means we have obtained your prior consent to such
                processing.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Privacy;

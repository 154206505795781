import React, { Component } from "react";
import Quote from "../components/quote";
import SimplyServeSmall from "../images/SimplyServeSmall.png";
import Chris from "../images/chris.jpeg";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
class About extends Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <div className="page home">
        <div className="page-title">
          SimplyServe
          <img src={SimplyServeSmall} alt="logo" />
          <div className="page-sub-title">
            say <i>yes</i> to more diners
          </div>
        </div>

        <div
          className="message-container"
          style={{
            maxWidth: this.props.maxWidth - 20 + "px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <div className="message">
            <div className="reading-time">Reading time approx 2 mins</div>
            <div className="title">
              <h2>Hi, I'm Chris</h2>
            </div>
            <div className="picture">
              <img src={Chris} alt="Chris" />
            </div>
            <div className="subtitle">
              <h4>founder of SimplyServe</h4>
            </div>
            <div className="content">
              <Quote text="The booking software that I wanted didn't exist, so we built it." />
              <p>
                Let me introduce you to Host, by SimplyServe. This is our brand
                new hosting software for restaurants and pubs.
              </p>
              <p>
                <span className="strong">What is hosting software?</span>
              </p>
              <p>
                Most pen &#038; paper replacement software is firmly geared
                towards web bookings. We've built hosting software to get the
                most from web bookings, telephone bookings and walk-ins. Check
                out the <Link to="/value">value</Link> page to find out how we
                do that.
              </p>
              <p>
                <span className="strong">Do I need hosting software?</span>
              </p>
              <p>
                "Need" is a strong word. But if your restaurant takes bookings,
                or is ever full, then you'll definitely seat more customers. The
                real question could be,{" "}
                <i>would you say "no" to these extra customers?</i>
              </p>
              <p>
                <span className="strong">
                  Why build a new pen &#038; paper diary replacement?
                </span>
              </p>
              <Quote text="We're here to challenge the status quo, and show you just how helpful a hosting platform can really be." />
              <p>So many reasons!</p>
              <p>
                Firstly, it seems crazy to me that in 2021 around 50% of
                restaurants are still using pen &#038; paper diaries. As a
                restaurant manager, a diner, and with years of experience
                working for a leading web booking platform, I can promise you
                that this is as good as turning away customers at your door.
              </p>
              <p>
                The short story is that I've seen and used many booking
                platforms, and the booking software that I wanted didn't exist,
                so we built it.
              </p>
              <p>
                And what did I want? The speed and flexibility of pen &#038;
                paper. A web booking widget that made human-like decisions (eg.
                smart table assigns, asking web diners if they can do 15 minutes
                later if it means I turn a table). A system that any waiter can
                pick up, greet a walk-in, and instantly know if they can sit now
                or how much of a wait time to offer them. A system that doesn't
                require me to spend 5 minutes shuffling bookings around every
                time a booking asks for a table by the window.
              </p>
              <p>I could go on.</p>
              <p>
                Ultimately every option out there is playing it very safe. We're
                here to challenge the status quo, and show you just how helpful
                a hosting platform can really be.
              </p>
              <p className="cta" style={{ marginBottom: "150px" }}>
                <h2>Let's Chat</h2>Host by SimplyServe gets a lot done. It's
                best to experience live and try it out. Once you’ve got stuck in
                for a week you’ll be dazzled by Host's simplicity.
                <br />
                <center>
                  <Link to="/demo">Book a demo</Link>
                </center>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;

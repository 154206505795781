import React, { Component } from "react";
import BrushRoundedIcon from "@material-ui/icons/BrushRounded";
import DateRangeRoundedIcon from "@material-ui/icons/DateRangeRounded";
import ScheduleRoundedIcon from "@material-ui/icons/ScheduleRounded";
import SpeedRoundedIcon from "@material-ui/icons/SpeedRounded";
import table from "../images/table-48.png";
import TimerRoundedIcon from "@material-ui/icons/TimerRounded";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import AutorenewRoundedIcon from "@material-ui/icons/AutorenewRounded";
import LocalOfferRoundedIcon from "@material-ui/icons/LocalOfferRounded";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import ArrowRightAltRoundedIcon from "@material-ui/icons/ArrowRightAltRounded";
import iPadSS1 from "../images/iPadSS1.png";
import iPadSS2 from "../images/iPadSS2.png";
import iPadSS3 from "../images/iPadSS3.png";
import iPadSS4 from "../images/iPadSS4.png";
import iPadSS5 from "../images/iPadSS5.png";
import iPadSS6 from "../images/iPadSS6.png";
import iPadSS7 from "../images/iPadSS7.png";
import iPadSS8 from "../images/iPadSS8.png";
import iPadSS9 from "../images/iPadSS9.png";
import iPadSS10 from "../images/iPadSS10.png";
import AutoAllocate from "./autoallocate";

import "../css/features.css";

class Features extends Component {
  // {
  //     label: "Pacings",
  //     title: "",
  //     image: <img />,
  //     icon: <icon />,
  //     text: <p>some text</p>,
  //   },
  state = {
    expand: false,
    selected: 0,
    features: [
      {
        label: "Design",
        title: "Thoughtful User Experience",
        image: <img src={iPadSS1} alt="screenshot" />,
        icon: <BrushRoundedIcon />,
        text: (
          <React.Fragment>
            <p>
              <span className="strong">Dark Theme</span>
            </p>
            <p>
              There's less strain on your eyes and the tablet won't illumiate
              the restaurant at night.
            </p>
            <p>
              <span className="strong">Layout</span>
            </p>
            <p>
              The same as a pen &#038; paper diary. Bookings running down the
              page, with space at the bottom to list your tables and mark the
              times the tables are booked.
            </p>
            <p>
              <span className="strong">Speed of use</span>
            </p>
            <p>
              It's fast to use. Nothing should be 2 taps when 1 tap would do.
              Nothing should be hidden in a settings menu if you need it readily
              on shift.
            </p>
          </React.Fragment>
        ),
      },
      {
        label: "Bookings",
        title: "",
        image: <img src={iPadSS7} alt="screenshot" />,
        icon: <MenuBookRoundedIcon />,
        text: (
          <React.Fragment>
            <p>
              <span className="strong">Quick</span>
            </p>
            <p>
              40-50% of bookings are online, so you save a lot of staff time on
              these. For the rest, add the covers &#038; time. No matter how
              many tables or bookings you have, Host will immediately show if
              there is availability or not.
            </p>
            <p>
              <span className="strong">Smart</span>
            </p>
            <p>
              Host doesn't only tell you if the time is available, it also let's
              you know of better options (which you can use, offer or ignore!).
              Perfect slot available if they dine in 1h15m, or if they come in
              15 mins later? Host will let you know.
            </p>
            <p>
              <span className="strong">You're in control</span>
            </p>
            <p>
              Typically you'll let Host assign a table so you don't have to, but
              you can manually assign a booking to a specific table if you want
              to.
            </p>
          </React.Fragment>
        ),
      },
      {
        label: "Auto Allocation",
        title: "",
        image: (
          <React.Fragment>
            <img src={iPadSS8} alt="screenshot" />
            <AutoAllocate />
          </React.Fragment>
        ),
        icon: <AutorenewRoundedIcon />,
        text: (
          <React.Fragment>
            <p>
              <span className="strong">Smart</span>
            </p>
            <p>
              Automatically allocated bookings are put on the most efficient
              tables to keep your availability open. Cancellations, changes,
              Host will constantly be tweaking it's allocations.
            </p>
            <p>
              <span className="strong">You're in control</span>
            </p>
            <p>
              Host knows what it's assigned and what you manually set. Host will
              only ever change it's own assigns, your manual allocations are
              safe.
            </p>
            <p>
              <span className="strong">Confidence</span>
            </p>
            <p>
              Booking arrives and wants T4 not T7? Your staff can instantly see
              if T4's bookings are automatically or manually assigned. If auto,
              your staff can have the confidence to say yes. Host will just swap
              all the future bookings over from T4 to T7.
            </p>
          </React.Fragment>
        ),
      },
      {
        label: "Tags",
        title: "",
        image: <img src={iPadSS9} alt="screenshot" />,
        icon: <LocalOfferRoundedIcon />,
        text: (
          <React.Fragment>
            <p>
              <span className="strong">Highlight</span>
            </p>
            <p>
              Don't miss important information about a booking just because the
              shift is busy. Tag a booking to put a colourful highlight next to
              their name so your staff know to read their notes.
            </p>
            <p>
              <span className="strong">Tailored</span>
            </p>
            <p>
              Create the tags that you need. Perhaps red for allergy, yellow for
              birthday, blue for VIP?
            </p>
            <p>
              <span className="strong">Detail</span>
            </p>
            <p>
              Tags are for quick highlights. Use them together with the booking
              notes to give specific details.
            </p>
          </React.Fragment>
        ),
      },
      {
        label: "Statuses",
        title: "",
        image: <img src={iPadSS10} alt="screenshot" />,
        icon: <CheckRoundedIcon />,
        text: (
          <React.Fragment>
            <p>
              <span className="strong">Informative</span>
            </p>
            <p>
              Perhaps you confirm your bookings the day before. Once confirmed
              you can change a booking's status from "Booked" to "Confirmed".
            </p>
            <p>
              <span className="strong">Fast</span>
            </p>
            <p>
              It's 2 taps. Tap the booking's status (button next to their name)
              that you want to update, tap the new status from the list. Done.
            </p>
            <p>
              <span className="strong">Tailored</span>
            </p>
            <p>The statuses need to suit you. Add as many as you need.</p>
          </React.Fragment>
        ),
      },
      {
        label: "Calender",
        title: "",
        image: <img src={iPadSS2} alt="screenshot" />,
        icon: <DateRangeRoundedIcon />,
        text: (
          <React.Fragment>
            <p>
              <span className="strong">Familiar</span>
            </p>
            <p>
              Just like pen &#038; paper, move to a different date in your diary
              to check availability and add new bookings.
            </p>
            <p>
              <span className="strong">Flexible</span>
            </p>
            <p>
              You set your normal schedule and change this as much as you need.
              We add handy labels to each day to show if your schedule is
              different (eg. Half Term or a Bank Holiday), and we also label the
              number of covers you have booked for that day's shifts.
            </p>
            <p>
              <span className="strong">Fast</span>
            </p>
            <p>
              From running your shift to checking how many covers for each shift
              next week? 1 tap of the screen. Browse next Thursday's bookings, 2
              taps.
            </p>
          </React.Fragment>
        ),
      },
      {
        label: "Shifts",
        title: "",
        image: <img src={iPadSS3} alt="screenshot" />,
        icon: <ScheduleRoundedIcon />,
        text: (
          <React.Fragment>
            <p>
              <span className="strong">Tailored</span>
            </p>
            <p>
              You can divide your day into shifts. Lunch and Dinner. Breakfast,
              Lunch and Dinner. Breakfast, Lunch, Afternoon Tea, Supper, Dinner,
              Midnight Snack. No limits, whatever you need.
            </p>
            <p>
              <span className="strong">Flexible</span>
            </p>
            <p>
              Lunch and Dinners during the week and also open for Breakfast at
              weekends? No problem. Different shifts for all next week for Half
              Term? Easy.
            </p>
            <p>
              <span className="strong">Shift Overview</span>
            </p>
            <p>
              Chef wants to know how busy we are without reading every booking?
              1 tap, hide the bookings and see what times your shift peaks
              (shown on the iPad).
            </p>
          </React.Fragment>
        ),
      },
      {
        label: "Pacings",
        title: "",
        image: <img src={iPadSS4} alt="screenshot" />,
        icon: <SpeedRoundedIcon />,
        text: (
          <React.Fragment>
            <p>
              <span className="strong">Control</span>
            </p>
            <p>
              You may not want 200 bookings at once. A pacing limit lets you set
              a maximum amount of covers you'd normally want in 30 minutes.
            </p>
            <p>
              <span className="strong">Flexible</span>
            </p>
            <p>
              You put a default when setting up a shift, so you can have a
              higher limit for busier shifts when you'll have more staff in.
            </p>
            <p>
              <span className="strong">On the fly</span>
            </p>
            <p>
              Got a big party in at 1230 and don't want any more bookings until
              1400? You can drag your pacing limits to quickly edit them for
              that day. Drag 1300 &#038; 1330 down to zero to take no more
              bookings at those times.
            </p>
          </React.Fragment>
        ),
      },
      {
        label: "Table Plans",
        title: "",
        image: <img src={iPadSS5} alt="screenshot" />,
        icon: (
          <div className="icon-nav-img-wrap">
            <img src={table} alt="table" />
          </div>
        ),
        text: (
          <React.Fragment>
            <p>
              <span className="strong">Tailored</span>
            </p>
            <p>
              Table plans are part of your shifts, so you can have different
              plans for different shifts or for custom days in your calender.
            </p>
            <p>
              <span className="strong">Flexible</span>
            </p>
            <p>
              Got a bar section which you wouldn't book but walk-ins can have?
              Easy. Turn a 4 into two 2s, or pull out a table from the back.
              Simple.
            </p>
            <p>
              <span className="strong">Informative</span>
            </p>
            <p>
              Tables list all bookings due &#038; also how long they've been
              sat. Current diner due to leave? The sat time turns orange, and if
              they need longer you can tap on the table and add 15 minutes. If
              they now won't turn in time for the next booking Host will
              re-allocate it for you.
            </p>
          </React.Fragment>
        ),
      },
      {
        label: "Turn Times",
        title: "",
        image: <img src={iPadSS6} alt="screenshot" />,
        icon: <TimerRoundedIcon />,
        text: (
          <React.Fragment>
            <p>
              <span className="strong">Tailored</span>
            </p>
            <p>
              Some restaurants rebook a table of 2 after 1h30m, some after 2h.
              Maybe you give your 4s longer than your 2s, and your 6s longer
              still. We will build all of this out for you, to your needs, when
              you first start.
            </p>
            <p>
              <span className="strong">Flexible</span>
            </p>
            <p>
              Perhaps on Valentines day or over Christmas you work slightly
              differently. Not a problem! Just like other features, you can tie
              different turn times to different shifts.
            </p>
            <p>
              <span className="strong">You're in control</span>
            </p>
            <p>
              Sometimes a booking needs longer, or someone wants to be
              in-and-out quickly, or a table is eating slowly. 1 tap to open the
              booking, then 1 tap per 15 mins to add or subtract.
            </p>
          </React.Fragment>
        ),
      },
    ],
  };

  navIcons = () => {
    const features = this.state.features;
    return (
      <React.Fragment>
        <div className="half">
          {features.map(
            (feature) =>
              features.indexOf(feature) < 5 && (
                <div className="nav-icon-wrap">
                  <button
                    className={
                      this.state.selected === features.indexOf(feature)
                        ? "nav-icon active"
                        : "nav-icon"
                    }
                    key={feature.label}
                    onClick={() =>
                      this.setState({ selected: features.indexOf(feature) })
                    }
                  >
                    {feature.icon}
                  </button>
                </div>
              )
          )}
        </div>
        <div className="half">
          {features.map(
            (feature) =>
              features.indexOf(feature) > 4 && (
                <div className="nav-icon-wrap">
                  <button
                    className={
                      this.state.selected === features.indexOf(feature)
                        ? "nav-icon active"
                        : "nav-icon"
                    }
                    key={feature.label}
                    onClick={() =>
                      this.setState({ selected: features.indexOf(feature) })
                    }
                  >
                    {feature.icon}
                  </button>
                </div>
              )
          )}
        </div>
      </React.Fragment>
    );
  };

  currentFeature = () => {
    const selected = this.state.selected;
    const feature = this.state.features[selected];
    return (
      <div className="features-window">
        <div
          className={
            this.state.expand ? "features-graphic selected" : "features-graphic"
          }
        >
          <div className="img-wrap">{feature.image}</div>
          <div
            className="expand"
            onClick={() =>
              this.setState({ expand: this.state.expand ? false : true })
            }
          >
            <ArrowRightAltRoundedIcon />
          </div>
        </div>
        <div className="features-content">
          <h2>
            {feature.icon} {feature.label}
          </h2>
          {feature.text}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="features-wrapper">
        <div className="nav-icons">{this.navIcons()}</div>
        {this.currentFeature()}
      </div>
    );
  }
}

export default Features;
